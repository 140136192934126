@import '../../../common/styles/_variable';

.chatBotHolder {
  // height: 100vh;
}

.chatContainer {
  height: 100%;

  .msger {
    display: flex;
    flex-direction: column;
    // max-height: 660px;
    border-radius: 20px;
    background: $primary-trans;
    padding: 20px;
    height: 100%;
    max-height: calc(100vh - 20px);
    justify-content: flex-end;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);

    .msger-inputarea {
      align-items: center;
      position: relative;
      display: flex;
      background: $white;
      border-radius: 28px;
      padding: 10px;
      margin-top: 10px;
      gap: 10px;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.1) inset;

      .moreBtn {
        width: 30px;
        height: 38px;
        min-width: 30px;
        border-radius: 100%;
        background: transparent;
        border: 0;
        color: $black;
        overflow: hidden;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: $font-size-large;
      }

      .msger-input {
        width: 100%;
        background: transparent;
        border: 0;
      }

      .msger-send-btn {
        margin-left: auto;
        border: 0;
        background: transparent;
        color: $primary-color;
        padding: 0;
        max-width: 32px;
        min-width: 32px;
        font-size: 25px;
      }
    }

    .moreOptions {
      padding-top: 10px;
      position: relative;
      justify-content: flex-end;

      /* width */
      ::-webkit-scrollbar {
        width: 2px;
        height: 6px;
      }

      .selectedFilesHolder {
        display: flex;
        flex-direction: row;
        overflow-x: auto;
        gap: 10px;
        padding-bottom: 3px;
        margin-bottom: -3px;

        .fileHolder {
          border-radius: 28px;
          font-size: $font-size-small;
          background: $white;
          border: 1px solid $primary-light;
          padding: 5px 8px;
          color: $primary-dark;
          display: flex;
          align-items: center;
          justify-content: center;
          white-space: nowrap;
          gap: 5px;

          button {
            background: transparent;
            border: 0;
            padding: 0;
            min-width: auto;
            width: auto;
            font-size: 25px;
            height: auto;
            min-height: auto;
            height: 25px;
            display: flex;
          }
        }
      }

      .bckBtn {
        background: transparent;
        display: flex;
        border: 0;
        color: $primary-color;
        align-items: center;
        justify-content: center;
        position: relative;
        left: 0;
        width: 40px;
        height: 100%;
        padding: 0;
        font-size: 28px;
      }

      .btnWrap {
        padding-left: 0;
        position: absolute;
        left: 0;
        z-index: 2;
        top: -22px;

        button {
          width: 35px;
          height: 35px;
          border-radius: 50px;
          background: $white;
          border: 1px solid $primary-light;
          color: $primary-color;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: $font-size-normal;
          position: relative;
          box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);

          &.attachmentBtn {
            input {
              width: 100%;
              opacity: 0;
              max-width: 100%;
              height: 100%;
              max-height: 100%;
              position: absolute;
              top: 0;
              cursor: pointer;
            }
          }

          &.qaTrigger {
            font-size: $font-size-large;
          }
        }
      }

      .preloaded {
        padding-left: 0;
        position: relative;
        width: 100%;
        display: flex;

        .qaScroll {
          display: flex;
          flex-direction: row;
          overflow-x: auto;
          padding-bottom: 3px;
          margin-bottom: -3px;

          button {
            border-radius: 28px;
            font-size: $font-size-small;
            background: $white;
            border: 1px solid $primary-light;
            min-height: 35px;
            padding: 5px 8px;
            color: $primary-dark;
            display: flex;
            align-items: center;
            justify-content: center;
            white-space: nowrap;
          }
        }
      }


      .btnWrap+.promptHolder {
        width: calc(100% - 44px);
      }

      .promptHolder {
        // padding-left: 50px;
        position: relative;
        width: 100%;
        display: flex;
        justify-content: flex-end;

        .prmptScroll {
          display: flex;
          flex-direction: row;
          overflow-x: auto;
          padding-bottom: 3px;
          margin-bottom: -3px;

          button {
            border-radius: 28px;
            font-size: $font-size-small;
            background: $white;
            border: 1px solid $primary-light;
            min-height: 35px;
            padding: 5px 8px;
            color: $primary-dark;
            display: flex;
            align-items: center;
            justify-content: center;
            white-space: nowrap;
          }
        }
      }
    }

    .msger-chat {
      padding: 0;
      display: flex;
      flex-direction: column;
      gap: 15px;
      height: calc(100% - 15px);
      overflow-y: auto;
      padding-bottom: 15px;

      .mainMsg {
        display: flex;
        flex-direction: column;
        gap: 15px;
      }

      .msg {
        display: flex;
        padding: 0;

        .msgInner {
          display: flex;
          background: $white;
          border-radius: 29px;
          color: $primary-dark;
          font-size: $font-size-small;
          gap: 10px;
          padding: 7px 10px;
          max-width: 100%;
          // align-items: flex-end;

          &.atcnmtInfo {
            align-items: center;

            .msg-text {
              text-align: right;
            }

            .msg-img {
              font-size: $font-size-medium;

            }
          }

          .msg-img {
            width: 30px;
            min-width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
              max-width: 80%;
            }
          }

          .msg-bubble {
            display: flex;
            align-items: center;
            margin-bottom: 5px;
            min-width: calc(100% - 50px);
            position: relative;

            .table-download {
              position: absolute;
              left: -40px;
              top: 40px;
              background: none;
              border: $primary-light solid 1px;
              border-radius: 4px;
              font-size: 16px;
              color: $primary-color;
              display: flex;
              justify-content: center;
              align-items: center;
              height: 30px;
            }

            .msg-text {
              overflow-wrap: anywhere;
              white-space: pre-line;
              max-width: 100%;

              // For hide empty div
              div:empty {
                display: none;
              }

              // For hide empty div

              &>div:first-child {
                max-width: 460px;
                width: 100%;
                overflow-x: auto;
                margin: 10px 0;
                padding-bottom: 5px;
                max-height: 450px;

                table {
                  width: 100% !important;

                  tr {
                    &:nth-child(even) {
                      background-color: $primary-lightest;
                    }
                  }

                  th,
                  td {
                    border-width: 1px;
                    white-space: nowrap;
                    text-align: left;
                    padding: 8px;
                    text-align: center;
                  }
                }
              }

              table {
                width: 100% !important;

                tr {
                  &:nth-child(even) {
                    background-color: $primary-lightest;
                  }
                }

                th {
                  border-width: 1px;
                  white-space: nowrap;
                  text-align: left;
                  padding: 8px;
                  text-align: center;
                }

                td {
                  border-width: 1px;
                  // white-space: nowrap;
                  text-align: left;
                  padding: 8px;
                  text-align: center;
                }
              }

              ul {
                li {
                  list-style: inherit;
                  list-style-type: circle;
                }
              }

              ol {
                li {
                  list-style: inherit;
                  list-style-type: circle;

                }
              }

              p {
                margin: 0;

                a {
                  color: rgb(75, 7, 233);
                  font-style: italic;
                }

                a:hover {
                  color: hotpink;
                  text-decoration: inherit;
                }

              }
            }
          }
        }

        &.right-msg {
          .msgInner {
            margin-left: auto;
            align-items: flex-end;

            // .msg-img {
            //   background: $primary-trans;
            //   border-radius: 100%;
            // }
            .msg-bubble {
              width: auto;
            }

          }
        }

      }
    }


  }

  &.Default1 {
    .msger {
      .msger-chat {
        .msg {
          .msgInner {
            background: transparent !important;
            padding: 0;

            .msg-img,
            .msg-bubble {
              background: transparent !important;
            }
          }
        }
      }

    }
  }

  &.Default2 {
    .msger {
      .msger-chat {
        .msg {
          .msgInner {
            background: transparent !important;
            padding: 0;

            .msg-img {
              padding: 5px;
              background: $white;
              border-radius: 29px;
              width: 38px;
              height: 38px;

            }

            .msg-bubble {
              padding: 7px 10px;
              background: $white;
              border-radius: 29px;
            }

          }
        }
      }

    }
  }
}





.msg-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.msg-info-name {
  margin-right: 10px;
  font-weight: bold;
}

.msg-info-time {
  font-size: 0.85em;
}

.left-msg .msg-bubble {
  border-bottom-left-radius: 0;
}

.mesgr-fileupload {
  width: 120px;
  color: transparent;

}

.typing {
  span {
    width: 5px;
    height: 5px;
    background-color: $primary-color;
    display: inline-block;
    margin: 1px;
    border-radius: 50%;

    &:nth-child(1) {
      animation: bounce 1s infinite;
    }

    &:nth-child(2) {
      animation: bounce 1s infinite .2s;
    }

    &:nth-child(3) {
      animation: bounce 1s infinite .4s;
    }
  }
}

@keyframes bounce {
  0% {
    transform: translateY(0px);
  }

  50% {
    transform: translateY(8px);
  }

  100% {
    transform: translateY(0px);
  }
}

.customHolder {
  .themeHolder {
    margin-top: -32px;

    @media (max-width: 767.98px) {
      margin-top: 20px;
    }

    .themeListing {
      display: flex;
      gap: 15px;

      .repeat {
        min-width: 33.33%;
        width: 33.33%;
        border-radius: 17px;
        background: $primary-lightest;
        padding: 10px;
        gap: 10px;
        display: flex;
        flex-direction: column;
        cursor: pointer;

        @media (max-width: 767.98px) {
          min-width: 30%;
        }

        .msgDesign {
          background: $white;
          display: flex;
          gap: 5px;
          padding: 5px;
          border-radius: 10px;

          .dp {
            width: 23px;
            height: 23px;
            background: $primary-lightest;
            border-radius: 50px;
            min-width: 23px;
          }

          .content {
            display: flex;
            flex-direction: column;
            width: 100%;
            justify-content: center;

            span {
              width: 60%;
              border-bottom: 2px solid $primary-color;
            }

            span+span {
              width: 100%;
              margin: 2px 0 2px;

            }

            span+span+span {
              width: 75%;
              margin: 0;
            }
          }

        }

        &.selected {
          border: 1px solid $primary-color;
        }

        &.Default1 {
          justify-content: center;

          .msgDesign {
            padding: 0;
            background: transparent;

            .dp {
              background: $primary-light;
            }
          }
        }

        &.Default2 {
          justify-content: center;

          .msgDesign {
            padding: 0;
            background: transparent;

            .dp {
              background: $white;
            }

            .content {
              background: $white;
              padding: 5px;
              border-radius: 12px;
            }
          }
        }
      }

    }
  }

  .colorHolder {
    .colorPickerHolder {
      display: flex;
      flex-direction: column;
      gap: 15px;

      .repeat {
        display: flex;
        gap: 15px;
        align-items: center;
        position: relative;

        .color-plate {
          position: absolute;
          z-index: 1;
          left: 62px;
          top: 0;
        }

        .colorPicker {
          border-radius: 4px;
          width: 47px;
          height: 47px;
          border: 1px solid $primary-color;
          cursor: pointer;

        }

        label {
          color: $primary-dark;
          font-weight: normal;
          margin-bottom: 0;
        }

        &:last-child {
          .color-plate {
            top: auto;
            bottom: 0;
          }
        }
      }
    }
  }

  .btnHolder {
    display: flex;
    gap: 10px;
  }
}



// //////////////



.chatRightItems {
  width: 80%;
  min-width: 80%;
  background: $white;
  // max-height: calc(100vh - 125px);
  overflow-y: auto;
  height: 100%;

  @media (max-width: 767.98px) {
    width: 100%;
    min-width: 100%;
  }

  a {
    padding: 15px;
    border-bottom: 1px solid $primary-light;
    display: flex;
    gap: 10px;

    &.box {
      background: $primary-light
    }

    &:last-child {
      border-bottom: 0;
    }

    .dp {
      width: 17px;
      height: 17px;
      border-radius: 50px;
      min-width: 17px;
    }
  }


}