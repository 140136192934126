@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;600;700&display=swap');
@font-face {
    font-family: 'neue_montrealBold';
    src: url('../fonts/neuemontreal-bold-webfont.woff2') format('woff2'),
         url('../fonts/neuemontreal-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'neue_montrealItalic';
    src: url('../fonts/neuemontreal-italic-webfont.woff2') format('woff2'),
         url('../fonts/neuemontreal-italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'neue_montrealMedium';
    src: url('../fonts/neuemontreal-medium-webfont.woff2') format('woff2'),
         url('../fonts/neuemontreal-medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'neue_montrealRegular';
    src: url('../fonts/neuemontreal-regular-webfont.woff2') format('woff2'),
         url('../fonts/neuemontreal-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

$primary-font: 'Poppins', sans-serif;
$secondary-font-regular: 'neue_montrealRegular';
$secondary-font-medium: 'neue_montrealMedium';
$secondary-font-bold: 'neue_montrealBold';

body {
    // font-family: 'Poppins', sans-serif;
    font-family: $secondary-font-regular;
    color: $black;
    font-size: $font-size-small;
}

.secondary-font-medium {
    font-family: $secondary-font-medium;
}

// headings
h1 {
    font-family: 'Poppins', sans-serif;
    font-size: $font-size-medium;
    color: $black;
    font-weight: 700;

    span {
        font-weight: 300;
    }
}

h2 {
    font-family: 'Poppins', sans-serif;
    font-size: $font-size-medium;
    font-weight: 600;

    span {
        font-weight: 300;

    }
}

h3 {
    font-family: 'Poppins', sans-serif;
    font-size: $font-size-normal;
    font-weight: 700;

    span {
        font-weight: 300;
    }
}
h4 {
    font-family: 'Poppins', sans-serif;
    font-size: $font-size-normal;
    font-weight: 600;

    
}
h5{
    font-size: $font-size-small;    
    span {
        color: $darkGray;
    }
}
h6{
    font-size: $font-size-small;
    font-weight: 600;
    span {
        color: $darkGray;
    }
}
p {
    font-family: $secondary-font-regular;
}
// buttons
.success {
    color: $green;
}
.danger {
    color: $danger;
    font-size: $font-size-small;
}
.primary-color {
    color: $primary-color !important;
}
.secondary-color {
    color: $secondary-color !important;
}
.font-black {
    color: $black;
}
.font-white {
    color: $white;
}
.big-font {
    font-size: $font-size-big;
}
.smallest-font {
    font-size: $font-size-smaller;
}

.btn-primary {
    background: $primary-color;
    border: 1px solid $primary-color;
    color: $white;
    font-size: $font-size-normal;
    min-height: 38px;
    padding: 0px 18px;
    min-width: 97px;
    border-radius: 50px;


    svg {
        margin-right: 15px;
    }

    &.small {
        min-height: 44px;
        height: auto;
        padding: 5px 10px;
        min-width: 97px;
        width: auto;
        font-size: $font-size-smaller;
        font-weight: 700;


    }

    &:disabled {
        background: transparent;
        border: 1px solid $primary-dark;
        color: $primary-dark;
        pointer-events: none;
        font-family: 'Poppins', sans-serif;
    }

    &:hover,
    &:active,
    &:focus,
    &:focus-visible {
        background: $primary-dark !important;
        outline: none;
        color: $white !important;
        opacity: 1 !important;
        border: 1px solid $primary-dark;

    }

}
.btn-tertiary {
    border:0;
    background: $grey;
    color: $primary-color;
    font-size: $font-size-small;
    min-height: 38px;
    padding: 0px 18px;
    min-width: 97px;
    border-radius: 50px;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    &:hover,
    &:active {
        background: $primary-color;
        color: $white;
    }
}
.btn-secondary {
    background: transparent;
    border: 1px solid $primary-color;
    color: $primary-color;
    font-size: $font-size-small;
    min-height: 38px;
    padding: 0px 18px;
    min-width: 97px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    font-family: 'Poppins', sans-serif;

    &:hover,
    &:active {
        background: $primary-color;
        border: 1px solid $primary-color;
        color: $white;
    }

    &.small {
        min-height: 44px;
        height: auto;
        padding: 5px 10px;
        min-width: 97px;
        width: auto;
        font-size: $font-size-smaller;
        font-weight: 700;


    }
    // svg {
    //     margin-right: 10px;
    // }
}

.plainBtn {
    min-width: 30px;
    width: 30px;
    color: $primary-dark;
    font-size: 15px;
    padding: 0;

    &:active,
    &:disabled,
    &:focus,
    &:visited {
        border: 0;
        outline: none !important;
    }
}

.danger {
    color: $secondary-color;
}

.splBtn {
    background: $primary-lightest;
    border: 0;
    width: 70px !important;
    font-size: 20px !important;
    min-width: auto !important;
}
.form-label {
    font-size: $font-size-small;
}
.btn {
    &.icon-btn {
        font-size: $font-size-large !important;
    }
}

.btn-reset {
    border: 1px solid #615070;
    background: transparent;
    color: #615070;
    border-radius: 4px;
}

.chck-holder {
    position: relative;
    // min-width: 25px;
    width: 25px;
    height: 25px;
    .form-check-input {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        opacity: 0;
        cursor: pointer;

    }
    .checkDesignHolder {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        border: 1px solid $primary-color;
        color: $primary-light;
        font-size: $font-size-normal;
        span {
            display: none;
        }
    }
    .form-check-input:checked+.checkDesignHolder {
        color: $white;
        background: $primary-color;
        border-color: $primary-color;
        span {
            display: block;
        }
    }
}

.addAgent-btn {
    width: 47px;
    background: $primary-light;
    border-radius: 0 4px 4px 0;
    border: 0;
    color: $white;
}

.templates-btn {
    width: 47px;
    background: $primary-light;
    border-radius: 0 4px 4px 0;
    border: 0;
    color: $white;
}

button:disabled {
    svg {
        stroke : $grey;
    }
 }