@import '../../common/styles/_variable';
@import '../../common/styles/typo-btns';

.loginWrapper {
    height: var(--window-height);

    .logo {
        font-family: $primary-font;
        font-size: $font-size-medium;
        font-weight: 700;
        position: absolute;
    }
    .spacing-fields{
        margin-top: 60px;
    }

    .leftWrapper {
        height: 100%;
        background: $white;
        -webkit-border-top-right-radius: 29px;
        -webkit-border-bottom-right-radius: 29px;
        -moz-border-radius-topright: 29px;
        -moz-border-radius-bottomright: 29px;
        border-top-right-radius: 29px;
        border-bottom-right-radius: 29px;
        -webkit-box-shadow: 10px 0px 20px 10px rgba(0, 0, 0, 0.07);
        -moz-box-shadow: 10px 0px 20px 10px rgba(0, 0, 0, 0.07);
        box-shadow: 10px 0px 20px 10px rgba(0, 0, 0, 0.07);

        @media (max-width: 991.98px) {
            -webkit-border-top-right-radius: 0;
            -webkit-border-bottom-right-radius: 0;
            -moz-border-radius-topright: 0;
            -moz-border-radius-bottomright: 0;
        }

        .loginCover {
            height: 100%;
            padding-top: 65px;
        }

        .scrollDiv {
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            overflow-y: auto;
        }

        .maxWidth {
            max-width: 493px;
            display: flex;
            flex-direction: inherit;
            align-items: center;

            .formHolder {
                width: 100%;
                align-items: center;
                display: contents;
                label {
                    width: 100%;
                    font-weight: 600;
                }

                .inputHolder {
                    width: 100%;
                    position: relative;
                    display: flex;


                    .form-control {
                        padding-right: 60px;
                    }

                    span {
                        position: absolute;
                        right: 0;
                        width: 50px;
                        top: 0;
                        height: 40px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-left: 1px solid $primary-light;
                    }
                }
                

                .form-check {
                    label {
                        font-weight: normal;

                    }
                }

                a {
                    color: $primary-color;
                }
            }
        }
        .maxWidth_600 {
            width: 100%;
            max-width: 600px;
        }
        

        .bottomHolder {
            .innerDiv {
                p {

                    position: relative;
                    display: flex;
                    align-items: center;
                    margin-bottom: 20px;
                    font-weight: 600;
                    padding: 0 100px;

                    &::before {
                        height: 1px;
                        left: 0;
                        right: 0;
                        border-bottom: 1px solid $grey;
                        position: absolute;
                        content: '';
                    }

                    span {
                        position: relative;
                        padding: 0 7px;
                        background: $white;
                    }
                }

                button {
                    border: 0;
                    background: 0;

                    &:hover,
                    &:focus,
                    &:active {
                        outline: 0;
                        background: transparent;
                        border: 0;
                    }
                }
            }

        }
    }

    .rightWrapper {
        background: rgb(231,222,233);
background: linear-gradient(333deg, rgba(231,222,233,1) 0%, rgba(255,255,255,0) 100%);
height: 100%;
align-items: center;
        .scrollDiv {
            width: 100%;
    max-height: 100%;
    overflow-y: auto;
        }
        .slideDiv {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
           

            p {
                font-size: $font-size-small;
                max-width: 60%;
                text-align: center;
            }

            .imgHolder {
                display: flex;
                justify-content: center;

                img {
                    max-width: 75%;
                }
            }
        }
    }
}