@import '../../common/styles/_variable';

body {
  background: transparent;
}

.chatBotHolder {
  // height: 100vh;
}

.iframe-sec {
  position: fixed;
  right: 15px;
  bottom: 0;
}

.chatBotTrigger {
  position: absolute;
  right: 0;
  bottom: 0;
  border: 0;
  width: 60px;
  height: 60px;
  border-radius: 50px;
  padding: 15px;
  background: $primary-light;

  img {
    transition: transform 0.3s ease;
  }

  &:hover {
    img {
      transform: rotate(360deg);
    }

  }
}


.chatIframe {
  // position: flex;
  // border: 1px solid green;
  height: calc(var(--window-height) - 30px);
  width: 100%;
  padding: 10px;
  overflow: scroll;

  .right-side-info {
    background: $whiteTrans;
  }

  .timeList {
    overflow: scroll;
    height: 60%;
    overflow-x: hidden;
  }

  .fileList {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .selectOption {
    padding-top: 10px;
    padding-right: 40px;

    .botButton {
      padding: 10px;
    }


  }


  a {
    padding: 15px;
    border-bottom: 1px solid $primary-light;
    display: flex;
    gap: 10px;

    &.box {
      background: $primary-light
    }

    &:last-child {
      border-bottom: 0;
    }

    .dp {
      width: 17px;
      height: 17px;
      border-radius: 50px;
      min-width: 17px;
    }
  }

  .chatIframeHolder {
    position: relative;
    height: 100%;
    bottom: 0;
    right: 0%;

    &.chatbot-collapsed {
      height: 60px;

      .chatbot-header {
        .chat-toggle {
          transform: rotate(180deg);
        }
      }
    }

    .chatbot-header {
      background: $primary-color;
      padding: 10px 15px;
      color: $white;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;

      h3 {
        cursor: pointer;
      }

      .chat-logo {
        width: 40px;
        height: 40px;
        border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
        background: $whiteTrans;
      }

      .chat-toggle {
        border: 0;
        min-width: 0;
        width: 35px;
        height: 35px;
        color: $white;
        transition: transform 0.3s ease;

        &:hover,
        &:focus,
        &:focus-visible,
        &:focus-within,
        &:active {
          outline: 0;
          border: 0;
          color: $white;
        }
      }
    }

    .chatContainer {
      height: 100%;
      width: 100%;

      .json-to-table td {
        min-width: 100% !important;
      }

      .msger {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        max-height: none;
        height: calc(100% - 60px);
      }
    }
  }

  .float-chat-container {
    position: relative;
    right: 15px;
    bottom: 15px;
    display: flex;
    flex-direction: column;

    .arrow-holder {
      display: none;
    }
  }
}