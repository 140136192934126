@import '../../../common/styles/_variable';

.sourceHolder {
    .tabHeader {
        display: flex;
        gap: 25px;
        position: relative;

        .tabButton {
            padding-bottom: 15px;
            position: relative;
            font-size: $font-size-small;
            cursor: pointer;

            &::after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                border-bottom: 5px solid $secondary-color;
                opacity: 0;
                transition: all 0.7s ease;

            }

            &:hover,
            &.active {
                &::after {
                    opacity: 1;
                }
            }

            &.active {
                font-weight: 700;
            }

            &.summary-tab {
                position: absolute;
                right: 0;
            }
        }
    }

    .tabContent {
        border-radius: 4px;
        margin-top: -3px;
        border: 1px solid $primary-light;
        padding-left: 0 !important;

        .topContent {
            border-bottom: 1px solid $primary-light;

            .commonDiv {
                .titleBar {
                    border-bottom: 1px solid $primary-light;
                    padding: 20px;
                }

                .content {
                    padding: 20px;
                    display: flex;
                    @media (max-width: 767.98px) {
                        padding: 0;
                    }

                    textarea {
                        &.form-control {
                            height: 209px;
                        }
                    }
                    .dsbtnHolder {
                        display: flex;
                        padding-left: 300px;
                    }
                    .formContainer {
                        width: 100%;

                        label {
                            margin-bottom: 10px;
                        }

                        .inputHolder {
                            display: flex;
                            width: 100%;
                            position: relative;
                            align-items: center;

                            .form-control {
                                padding-right: 60px;
                            }

                            .icon {
                                position: absolute;
                                right: 10px;
                                width: 40px;
                                height: 40px;
                                color: $primary-color;
                                display: flex;
                                align-items: center;
                                justify-content: flex-end;
                                font-size: 30px;
                                background: transparent;
                                border: 0;
                                padding: 0;
                            }
                        }

                        .repeatDiv {
                            background: $primary-lightest;
                            border-radius: 10px;
                            padding: 10px;
                            position: relative;

                            .form-control {
                                &:disabled {
                                    border: 0;
                                    // pointer-events: none;
                                    padding: 0;
                                    background: transparent;
                                }
                            }

                            .qstnHolder {
                                display: flex;
                                // gap: 10px;

                                .form-control {

                                    font-weight: 700;
                                }

                                .btnHolder {
                                    padding: 0;
                                    display: flex;
                                    justify-content: center;
                                    gap: 10px;

                                    button {
                                        &:first-child {
                                            margin-left: 10px;
                                        }
                                    }
                                }
                            }

                            .aswrHolder {
                                textarea {
                                    &.form-control {
                                        height: 100px;
                                    }
                                }
                            }

                        }

                        .accordionContainer {
                            .accordion-header {
                                position: relative;
                                padding-right: 50px;

                                .accordionTrgr {
                                    position: absolute;
                                    right: 0;
                                    top: 0;
                                    bottom: 0;
                                    width: 50px;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    font-size: 20px;
                                    cursor: pointer;
                                }

                                &.open {
                                    .accordionTrgr {
                                        svg {
                                            transform: rotate(180deg);
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .drag_drop_container {
                        position: relative;
                        display: flex;
                        width: 100%;

                        .leftContainer {
                            padding-right: 21px;
                            position: relative;
                            display: flex;
                            width: 40%;
                            align-items: center;
                            justify-content: center;

                            @media (max-width: 767.98px) {
                                width: 100%;
                            }

                            .drop-file-upload {
                                display: flex;
                                justify-content: center;
                                flex-direction: column;
                                align-items: center;  
                                
                                .upload-icon {
                                    margin-bottom: 15px;
                                }
                            }

                            p {
                                text-align: center;
                                margin-bottom: 0;
                                color: $darkGray;
                            }

                            &::after {
                                content: '';
                                position: absolute;
                                right: 0;
                                top: -20px;
                                bottom: -20px;
                                border-right: 1px solid $primary-light;
                                @media (max-width: 767.98px) {
                                    border-right: 0;
                                    border-bottom: 1px solid $primary-light;
                                    left: 0;
                                    bottom: 0;
                                }

                            }

                        }

                        .rightContainer {
                            overflow-y: auto;
                            max-height: 209px;
                            height: 209px;

                            padding-left: 20px;
                            position: relative;
                            display: flex;
                            width: 60%;
                            flex-direction: column;

                            @media (max-width: 767.98px) {
                                width: 100%;
                                padding-right: 20px;
                            }

                            .uploadHead {
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                margin-bottom: 20px;
                                @media (max-width: 767.98px) {
                                    margin-top: 20px;
                                }


                                .checkBox {
                                    display: flex;
                                    label {
                                        line-height: 25px;
                                        margin-left: 13px;
                                    }
                                }
                            }

                            .repeat-container {
                                display: flex;
                                gap: 13px;
                                align-items: center;
                            }

                            .repeat {
                                width: 100%;
                                background: $primary-lightest;
                                border: 1px solid $primary-light;
                                color: $darkGray;
                                padding: 5px;
                                border-radius: 5px;
                                display: flex;                                
                                align-items: center;
                                justify-content: space-between;
                                margin: 2px 0;
                                gap: 15px;

                                p {
                                    margin: 0;
                                }
                                

                                button {
                                    width: 33px;
                                    height: 33px;
                                    min-width: 33px;
                                    border-radius: 50px;
                                    background: $white;
                                    border: 1px solid $primary-light;
                                    color: $primary-color;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;

                                }

                                .file-name {
                                    display: flex;
                                    gap: 13px;
                                    p {
                                        margin-bottom: 0;
                                        text-overflow: ellipsis;
                                        overflow: hidden;
                                        white-space: nowrap;
                                        color: $primary-dark;
                                        width: 100px;
                                    }
                                }

                                .website {
                                    p {
                                        width: auto;
                                    }
                                }

                                .status {
                                    width: 20px;
                                    height: 20px;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    font-size: 30px; 
                                    color: $primary-dark;                                                               
                                }  
                                .file-size {
                                    p {
                                        color: $darkGray;
                                        margin-bottom: 0;
                                        text-overflow: ellipsis;
                                        overflow: hidden;
                                        white-space: nowrap;   
                                        font-weight: 600;                                         
                                    }
                                } 
                                .file-action {
                                    display: flex;
                                    gap: 5px;
                                    margin-left: auto;
                                    .trained-label {
                                        border: solid 1px $primary-dark;
                                        color: $dark;
                                        border-radius: 20px;
                                        padding: 0 10px;
                                        display: flex;
                                        align-items: center;
                                        gap: 5px;                                        
                                    }  
                                }

                                &.new {
                                    .trained-label {
                                        opacity: .5;
                                    }
                                }
                                
                                &.inprogress {
                                    .status {                                       
                                        color: $yellow;                                                               
                                    }  
                                    .file-size {
                                        p {
                                            color: $darkGray;                                                                                    
                                        }
                                    } 
                                    .file-action {                                       
                                        .trained-label {
                                            border: solid 1px $yellow;
                                            background: $light-yellow;
                                            color: $yellow;                                                                                        
                                            // label {
                                            //     color: $yellow;
                                            // }
                                            svg {
                                                font-size: 16px;
                                            } 
                                        }  
                                    }
                                                                   
                                }

                                &.completed {
                                    .status {                                         
                                        color: $green;                                                               
                                    }  
                                    .file-size {
                                        p {
                                            color: $darkGray;                                                                                    
                                        }
                                    } 
                                    .file-action {                                        
                                        .trained-label {
                                            border: solid 1px $green;
                                            background: $light-green;
                                            color: $green;                                              
                                            // label {
                                            //     color: $green;
                                            // }  
                                            svg {
                                                font-size: 16px;
                                            }                                        
                                        }  
                                    }
                                                                        
                                }

                                &.failed {
                                    .status {                                        
                                        color: $danger;                                                              
                                    }   
                                    .file-size {
                                        p {
                                            color: $danger;                                                                                      
                                        }
                                    } 
                                    .file-action {                                        
                                        .trained-label {
                                            border: solid 1px $danger;
                                            background: $light-danger;
                                            color: $danger;                                            
                                            // label {
                                            //     color: $danger;
                                            // } 
                                            svg {
                                                font-size: 16px;
                                            }                                          
                                        }  
                                    }                                   
                                }                                
                            }
                        }
                    }

                    &.instructions {
                        .form-control {
                            border: 0;
                        }
                    }

                    &.qaContent {
                        height: 245px;
                        overflow-y: auto;
                    }

                    &.dsContent {
                        overflow-x: auto;
                    }

                   
                }

            }

            .summary-accordion {
                padding: 10px;
            }
        }

        .btnHolder {
            padding: 20px;
        }
    }
}