@import '../../../common/styles/_variable';
.dataTable {
  // border: 1px solid $primary-light;
  border-radius: 5px;
  width: 100%;
  overflow-x: auto;
}
.iframeCodeHolder {
  position: relative;
  width: 100%;
  .form-control {
    height: 150px;
    border-radius: 5px;
    background: $primary-lightest;
    padding-bottom: 45px;
  }
  .btn {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 95px;
    height: 35px;
    background: $primary-color;
    color: $white;
    font-size: $font-size-small;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0;
    -webkit-border-top-left-radius: 5px;
-webkit-border-bottom-right-radius: 5px;
-moz-border-radius-topleft: 5px;
-moz-border-radius-bottomright: 5px;
border-top-left-radius: 5px;
border-bottom-right-radius: 5px;
  }
}
