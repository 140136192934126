@import "../../common/styles/variable";

.search {
  border-radius: 50px;
  border: 1px solid $primary-light;
  padding: 0 4px 0 0;
  display: flex;
  align-items: center;
  gap: 10px;
  overflow: hidden;

  .group {
    height: 100%;
    position: relative;
    select {
      padding-left: 8px;
    }
    &::after {
        content: '';
        width: 0; 
        height: 0; 
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-top: 10px solid $primary-light;
        position: absolute;
        bottom: 12px;
        right: 12px;
        pointer-events: none;
    }
  }
  .agent {
    height: 100%;
    position: relative;

    &::after {
        content: '';
        width: 0; 
        height: 0; 
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-top: 10px solid $primary-light;
        position: absolute;
        bottom: 12px;
        right: 12px;
        pointer-events: none;
    }
  }

  select {
    width: 150px;
    height: 100%;
    border-right: 1px solid $primary-light;
    border-top: 0;
    border-bottom: 0;
    border-left: 0;
    appearance: none;
    position: relative;

    &::after {
      position: absolute;
      width: 10px;
      height: 10px;
      content: "";
      top: 0;
      right: 0;
    }
  }
  input {
    width: 180px;
    height: 100%;
    border: 0;
    padding-left: 10px;
  }
  .search-btn {
    padding: 0 23px;
    font-size: 12px;
    color: $white;
    background: $primary-color;
    border-radius: 50px;
    border: none;
    height: 32px;
    font-weight: bold;
  }
}
