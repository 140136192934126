@import '../../common/styles/_variable';

.userHolder {
    padding-top: 20px;
    .tabHeader {
        display: flex;
        gap: 25px;
        position: relative;
        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            border-bottom: 1px solid $primary-light;
        }
        .tabButton {
            padding-bottom: 15px;
            position: relative;
            font-size: $font-size-normal;
            cursor: pointer;

            &::after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                border-bottom: 5px solid $primary-color;
                opacity: 0;
                transition: all 0.7s ease;

            }

            &:hover,
            &.active {
                &::after {
                    opacity: 1;
                }
            }

            &.active {
                font-weight: 700;
            }
        }
    }


}

.dataTable {
    // border: 1px solid $primary-light;
    border-radius: 5px;
    width: 100%;
    overflow-x: auto;

    .span {
        background: #615070;


    }
    .btn{
        
       font-size: $font-size-medium;
    }
}