@import '../../common/styles/_variable';

body {
  background: transparent;
}

.chatBotHolder {
  // height: 100vh;
}

.iframe-sec {
  position: fixed;
  right: 15px;
  bottom: 0;
}

.chatBotTrigger {
  position: absolute;
  right: 0;
  bottom: 0;
  border: 0;
  width: 60px;
  height: 60px;
  border-radius: 50px;
  padding: 15px;
  background: $primary-light;

  img {
    transition: transform 0.3s ease;
  }

  &:hover {
    img {
      transform: rotate(360deg);
    }
  }
}

.chatIframeHolder {
  position: fixed;
  height: var(--window-height);
  width: 100vw;
  bottom: 0;
  right: 0;

  &.chatbot-collapsed {
    height: 60px;

    .chatbot-header {
      .chat-toggle {
        transform: rotate(180deg);
      }
    }
  }

  .chatbot-header {
    background: $primary-color;
    padding: 10px 15px;
    color: $white;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;

    h3 {
      cursor: pointer;
    }

    .chat-logo {
      width: 40px;
      height: 40px;
      border-radius: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
      background: $whiteTrans;
    }

    .chat-toggle {
      border: 0;
      min-width: 0;
      width: 35px;
      height: 35px;
      color: $white;
      transition: transform 0.3s ease;

      &:hover,
      &:focus,
      &:focus-visible,
      &:focus-within,
      &:active {
        outline: 0;
        border: 0;
        color: $white;
      }
    }
  }

  .chatContainer {
    height: 100%;
    width: 100%;
    align-items: baseline;
    
    .json-to-table td {
      min-width: 100% !important;
    }
    
    .msger {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      max-height: none;
      height: calc(100% - 60px);
    }
  }
}

.float-chat-container {
  position: fixed;
  right: 15px;
  bottom: 15px;
  display: flex;
  flex-direction: column;
  
  .arrow-holder {
    display: none;
  }
}

.msg.left-msg {
    &.selectable {
        cursor: pointer;
        transition: transform 0.2s ease;
        
        &:hover {
            transform: translateX(4px);
        }
        
        .improvement-version {
            .click-hint {
                font-size: 0.8em;
                opacity: 0.7;
                margin-left: 8px;
            }
        }
    }
    
    &.selected {
        border: 1px solid rgba(255, 255, 255, 0.2);
        
        .improvement-version {
            .selected-mark {
                color: #4CAF50;
                margin-left: 8px;
                font-weight: 500;
            }
        }
    }
    
    .improvement-version {
        font-size: 0.9em;
        margin-bottom: 4px;
        opacity: 0.8;
    }
}

.suggestions-container {
    .suggestions-list {
        display: flex;
        flex-direction: column;
        gap: 10px;
        
        .suggestions-header {
            font-weight: 500;
            margin-bottom: 8px;
            opacity: 0.8;
        }
        
        .suggestion-item {
            padding: 12px;
            border-radius: 8px;
            background: rgba(255, 255, 255, 0.1);
            cursor: pointer;
            transition: all 0.2s ease;
            
            &:hover {
                background: rgba(255, 255, 255, 0.2);
                transform: translateX(4px);
            }
            
            &.selected {
                background: rgba(255, 255, 255, 0.3);
                border: 1px solid rgba(255, 255, 255, 0.4);
            }
        }
    }
    
    .suggestions-loading {
        padding: 12px;
        opacity: 0.8;
        font-style: italic;
    }
}

.improvement-container {
    .msg-bubble {
        padding: 24px;
        background: #fff !important;
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: flex-start;
        width: 100%;
        
        .improvement-header {
            color: #1a73e8;
            font-size: 16px;
            font-weight: 600;
            text-align: left;
            width: 100%;
            margin-bottom: 4px;
        }

        .original-text {
            font-size: 14px;
            line-height: 1.3;
            color: #333;
            text-align: left;
            width: 100%;
            margin-bottom: 16px;
        }

        .suggestions-header {
            color: #1a73e8;
            font-size: 15px;
            font-weight: 600;
            text-align: left;
            width: 100%;
        }

        .suggestions-list {
            display: flex;
            flex-direction: column;
            gap: 12px;
            margin-top: 8px;
            width: 100%;

            .suggestion-box {
                background: rgba(26, 115, 232, 0.1);
                padding: 16px;
                border-radius: 25px;
                cursor: pointer;
                transition: all 0.2s ease;
                font-size: 14px;
                line-height: 1.5;
                color: #333;
                text-align: left;

                &:hover {
                  transform: translateX(4px);
                  background: rgba(26, 115, 232, 0.15);

                  &:after {
                      content: "Click to Update";
                      position: absolute;
                      right: 0px; // Position to the right of the box
                      top: 80%;
                      transform: translateY(-50%);
                      background: white;
                      color: black;
                      padding: 6px 8px;
                      border-radius: 4px;
                      font-size: 9px;
                      box-shadow: 0 2px 4px rgba(0,0,0,0.1);
                      white-space: nowrap;
                      z-index: 1000;
                  }
              }

                &.selected {
                    background: rgba(26, 115, 232, 0.2);
                    border: 1px solid rgba(26, 115, 232, 0.3);
                }
            }
        }

        .regenerate-container {
            display: flex;
            justify-content: flex-end;
            width: 100%;
            
            .regenerate-btn {
                background: $primary-color;
                color: $white;
                border: none;
                padding: 8px 16px;
                border-radius: 20px;
                cursor: pointer;
                display: flex;
                align-items: center;
                gap: 8px;
                transition: background 0.3s ease;

                svg {
                    fill: $white;
                }

                &:hover {
                    background: darken($primary-color, 10%);
                }

                &:disabled {
                    background: lighten($primary-color, 20%);
                    cursor: not-allowed;
                }

                .loader {
                    border: 2px solid $white;
                    border-top: 2px solid transparent;
                    border-radius: 50%;
                    width: 16px;
                    height: 16px;
                    animation: spin 0.8s linear infinite;
                }
            }
        }
    }
}

.suggestion-loader {
  display: flex;
  justify-content: center;
  margin: 10px 0;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}