@import '../../../common/styles/_variable';

.create-container {
    .right-container {
        position: relative;

        &::before {
            content: '';
            position: absolute;
            left: 1.5rem;
            right: -48px;
            top: -25px;
            bottom: 0;
            border-top-left-radius: 31px;
            background: rgb(246, 247, 251);
            background: linear-gradient(144deg, rgba(246, 247, 251, 1) 0%, rgba(255, 255, 255, 1) 100%);
        }

        .inner-deck {
            position: relative;

            .chatRe {
                .user-dp {
                    background: $grey;
                    width: 45px;
                    height: 45px;
                    border-radius: 50px;
                    align-items: center;
                    justify-content: center;
                    color: $primary-color;
                    display: flex;
                    font-size: 25px;
                }

                .answer-heading {
                    position: relative;
                    border-radius: 15px;
                    border-top-left-radius: 0;
                    background: $grey;
                    padding: 0 20px;
                    height: 45px;
                    display: flex;
                    align-items: center;

                    &::before {
                        display: inline-block;
                        width: 0;
                        height: 0;
                        border-style: solid;
                        border-width: 0px 25px 15px 0;
                        border-color: transparent $grey transparent transparent;
                        content: '';
                        position: absolute;
                        left: -24px;
                        top: 0;
                    }
                }


            }

            .info-deck {
                padding-left: 61px;
                align-items: flex-end;
                .info-content {
                    padding: 20px 30px 20px 45px;
                    background: $primary-lightest;

                    border-top-left-radius: 15px;
                    border-top-right-radius: 15px;
                    border-bottom-left-radius: 15px;
                    position: relative;
                    &::after {
                        display: inline-block;
                        width: 0;
                        height: 0;
                        border-style: solid;
                        border-width: 15px 0px 0px 25px;
                        border-color: transparent transparent transparent $primary-lightest;
                        content: '';
                        position: absolute;
                        right: -24px;
                        bottom: 0;
                    }
                    ul {
                        li {
                            position: relative;
                            &::before {
                                content: '';
                                width: 10px;
                                height: 10px;
                                border-radius: 3px;
                                background: $primary-color;
                                position: absolute;
                                left: -20px;
                                top: 7px;
                            }
                        }
                    }
                }
                .robo {
                    margin-left: -15px;
                    position: relative;
                }
            }
        }
    }
}