:root {
    --window-height: 100vh;
    background: #e0e0e0;
    

    @media (min-width: $larger-device-width) {
        --window-height: 1000px;
    }
}
#root {
    // display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.outer-wrapper {
    // @media (min-width: 1440px) {
    // display: flex;
    // justify-content: center;
    // align-items: center;
    // width: 100%;
    // height: var(--window-height);
    // }
}

body {
    @media (min-width: $larger-device-width) {
        background: $light;
    }

    .wrapper {
        max-width: 1920px;
        margin: auto;
        height: var(--window-height);
        background: $white;
        position: relative;

        @media (min-width: $larger-device-width) {
            
            // padding: 5% 0;
            border-radius: 30px;
            height: 960px;
            overflow: hidden;

            .wrapper-inner {
                border-radius: 29px;
                height: 100%;
                box-shadow: 0 0 5px $overlay;
                overflow: hidden;
                position: relative;
                background: $white;

            }

            .sideBar {
                position: absolute;
            }
        }
    }
}

.rightSection {
    // padding: 26px 60px 30px 215px;
    padding: 26px 60px 12px 215px;
    height: 100vh;
    overflow: auto;
    @media (max-width: 991.98px) {
        padding: 26px 30px 12px 30px;
    }

    .breadcrumb {
        li {
            position: relative;
            padding-right: 23px;
            margin-right: 15px;
            display: flex;
            align-items: center;
            padding-left: 0;
            color: $black;
            font-family: 'Poppins', sans-serif;

            &::before {
                display: none;
            }

            &::after {
                position: absolute;
                right: 0;
                content: ">";
            }

            &:last-child {
                &::after {
                    display: none;
                }
            }

            &.active {
                font-weight: normal;
                color: $primary-color;
                margin-right: 0;
                padding-right: 0;

            }

            .icon {
                margin-right: 5px;
                font-size: 22px;
                line-height: 0;
                margin-left: -27px;
                color: $primary-color;
            }

            a {
                display: flex;
                align-items: center;
            }
        }
    }

    .contentWrapper {
        .iconHolder {
            width: 170px;
            height: 170px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            font-size: $font-size-biggest;
            color: $primary-color;

        }
        .boxHolder {
            .box {
                display: flex;
                border: 1px solid $borderGray;
                border-radius: 4px;
                color: $primary-dark;
                font-size: $font-size-smaller;
                font-weight: 600;
                gap: 20px;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                padding: 50px 15px;
                height: 100%;
                position: relative;
                width: 100%;
                color: $dark;

                .iconHolder {
                    width: 69px;
                    height: 69px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    font-size: $font-size-biggest;
                    color: $secondary-color;

                }
                .templateIconHolder {
                    width: 24px;
                    height: 24px;
                    font-size: 24px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    pointer-events: none;
                    font-size: $font-size-biggest;
                    color: $secondary-color;
                
                }

                .textHolder {
                    cursor: pointer;

                }

                .icons {
                    position: absolute;
                    right: 0;
                    top: 5px;
                    gap: 10px;
                    display: none;
                    font-size: $font-size-normal;                    

                    button {
                        border: 0;
                        background: transparent;

                        &.close {
                            color: $primary-color;
                        }
                        &.edit {
                            color: $primary-color;
                        }
                    }
                }

                .editIcons {
                    position: absolute;
                    right: 25px;
                    top: 5px;
                    gap: 10px;
                    display: none;
                    font-size: $font-size-normal;

                    button {
                        border: 0;
                        background: transparent;

                        &.close {
                            color: $secondary-color;

                        }
                    }
                }

                &:hover {
                    .icons {
                        display: flex;
                    }

                    .editIcons {
                        display: flex;
                    }
                }

                &.create-button {
                    background: $primary-color;

                    .iconHolder {
                        width: 100%;
                        height: auto;
                        font-size: 60px;
                        color: $white;

                    }

                    span {
                        background: $secondary-light;
                        border-radius: 22px;
                        height: 44px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: $primary-dark;
                        font-weight: 700;
                        font-size: $font-size-smaller;
                        width: 100%;
                    }

                }
            }
        }

        .promtSelection {
            .boxHolder {
                .box {
                    padding: 15px;
                    align-items: normal;
                    justify-content: flex-start;

                    .iconHolder {
                        width: 24px;
                        height: 24px;
                        font-size: 24px;
                        cursor: default;
                    }

                    .form-check-label {
                        margin-bottom: 0;
                        font-size: $font-size-small;
                        font-weight: 600;
                        color: $black;
                    }

                    p {
                        margin-bottom: 0;
                        font-size: $font-size-normal;
                        font-weight: 500;
                        overflow-wrap: break-word;
                    }

                }
            }
        }

        .chatOptionSelection {
            .boxHolder {
                .box {
                    padding: 15px;
                    align-items: normal;
                    justify-content: flex-start;

                    .iconHolder {
                        width: 24px;
                        height: 24px;
                        font-size: 24px;
                    }

                    .form-check-label {
                        margin-bottom: 0;
                        font-size: $font-size-small;
                        font-weight: 600;
                        color: $black;
                    }

                    p {
                        margin-bottom: 0;
                        font-size: $font-size-normal;
                        font-weight: 500;
                        overflow-wrap: break-word;
                    }

                }
            }
        }

        .template-section {
            height: 100%;

            .template-lists {
                height: calc(100% - 95px);
                overflow-y: auto;
                overflow-x: hidden;
                padding-right: 2px;
            }

            .boxHolder {
                .box {
                    padding: 15px !important;
                    align-items: normal !important;
                    // justify-content: flex-start !important;

                    .iconHolder {
                        width: 24px;
                        height: 24px;
                        font-size: 24px;
                    }

                    .info-holder {
                        span {
                            font-size: $font-size-smaller;
                            color: $greyText;
                        }
                    }

                    .form-check-label {
                        margin-bottom: 0;
                        font-size: $font-size-small;
                        font-weight: 600;
                        color: $black;
                    }

                    p {
                        margin-bottom: 0;
                        font-size: $font-size-normal;
                        overflow-wrap: break-word;
                        font-weight: normal;
                    }

                    .bottom-holder {
                        .tag {
                            background: $secondary-lighter;
                            border-radius: 25px;
                            padding: 5px 7px;
                            color: $secondary-color;
                        }
                    }

                    .icon-btn {
                        width: 25px;
                        height: 25px;
                        border: 1px solid $primary-color;
                        color: $primary-color;
                        font-size: $font-size-normal !important;
                        background: transparent;
                        padding: 0;
                        line-height: 13px;

                        &:hover {
                            background: $primary-color;
                            color: $white;
                        }

                    }

                    .chck-holder {
                        position: relative;
                        width: 25px;
                        height: 25px;

                        .form-check-input {
                            position: absolute;
                            left: 0;
                            top: 0;
                            height: 100%;
                            width: 100%;
                            opacity: 0;
                            cursor: pointer;

                        }

                        .checkDesignHolder {
                            width: 100%;
                            height: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border-radius: 4px;
                            border: 1px solid $primary-color;
                            color: $primary-light;
                            font-size: $font-size-normal;

                            span {
                                display: none;
                            }
                        }

                        .form-check-input:checked+.checkDesignHolder {
                            color: $white;
                            background: $primary-color;
                            border-color: $primary-color;

                            span {
                                display: block;
                            }
                        }
                    }
                }
            }
        }
    }

    .basic-holder {
        height: 100%;

        .template-name-btn {}

        .stepOne {
            height: calc(100% - 40px);
            overflow-y: auto;
            overflow-x: hidden;
            padding-right: 25px;
            @media (max-width: 575.98px) {
                height: auto;
                padding-right: 0;
            }
            
            .basic-details-form {
                @media (max-width: 575.98px) {
                    flex-direction: column;
                }
            }

            .upload-button-container {
                .uploadButton {
                    position: relative;
                    .uploadTrigger {
                        width: 170px;
                        height: 170px;
                        background: $primary-lightest;
                        border-radius: 4px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;

                        .icon {
                            font-size: $font-size-big;
                        }
                        label {
                            font-size: $font-size-smaller;
                            font-weight: bold;
                            color: $black;
                            margin: 0;
                        }
                    }
                    input {
                        position: absolute;
                        top: 0;
                        left: 0;
                        height: 170px;
                        opacity: 0;
                    }
                }
            }
            // .right-form-container {
            //     flex: 1;
            // }
        }
    }

    .crb-source {
        height: 100%;

        .source-tab {
            height: calc(100% - 40px);
            overflow-y: auto;
            overflow-x: hidden;
            padding-right: 2px;

            .tabContent {
                height: calc(100% - 65px);

                .topContent {
                    height: 100%;
                    overflow: auto;

                    .commonDiv {
                        height: 100%;

                        .content {
                            height: calc(100% - 62px);

                            .drag_drop_container {
                                height: 100%;
                                @media (max-width: 767.98px) {
                                    flex-direction: column;
                                }

                                .leftContainer {
                                    height: 100%;
                                }

                                .rightContainer {
                                    height: 100%;
                                    max-height: 100%;
                                }
                            }
                        }
                    }
                }

                .qaContent {
                    overflow-y: unset;

                    .formContainer {
                        height: 100%;
                        overflow-y: auto;
                    }
                }
            }

        }
    }

    .prompt-holder {
        height: 100%;

        .prompt-listing {
            height: calc(100% - 80px);
            overflow-y: auto;
            overflow-x: hidden;
            padding-right: 2px;
        }
    }

   

    .stepTwo {
        .leftImage {
            .uploadButton {
                background: $secondary-light;
                color: $primary-dark;
                border-radius: 4px;
                display: flex;
                align-items: center;
                justify-content: center;
                min-height: 181px;
                position: relative;
                flex-direction: column;
                gap: 5px;
                padding: 15px;

                .uploadTrigger {
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                }

                .dpDisplay {
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    
                }

                .icon {
                    font-size: 27px;
                }

                label {
                    margin-bottom: 0;
                    font-size: $font-size-smaller;
                    font-weight: 700;
                    text-align: center;
                }

                input[type="file"] {
                    position: absolute;
                    left: 0;
                    top: 0;
                    right: 0;
                    height: 100%;
                    opacity: 0;
                }
            }
        }

        .rightDetails {
            padding-left: 50px;

            .statusHolder {
                .topDiv {
                    gap: 25px;
                }

                .icon {
                    -moz-transform: scale(-1, 1);
                    -webkit-transform: scale(-1, 1);
                    -o-transform: scale(-1, 1);
                    -ms-transform: scale(-1, 1);
                    transform: scale(-1, 1);
                    font-size: 60px;
                    color: $primary-dark;
                    display: flex;
                }

                h4 {
                    color: $primary-dark;
                    font-size: $font-size-bigger;
                    margin-bottom: 5px;
                    font-weight: 700;
                    line-height: 45px;
                }

                p {
                    font-size: $font-size-normal;
                    color: $primary-color;
                    line-height: 20px;
                }

                ul {
                    margin-left: -35px;
                    position: relative;

                    &::before {
                        content: '';
                        left: 6px;
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        border-left: 1px solid $primary-light;
                    }

                    li {
                        padding-left: 35px;
                        position: relative;
                        margin-bottom: 10px;

                        &:last-child {
                            margin-bottom: 0;
                        }

                        .iconSpan {
                            position: absolute;
                            left: 0;
                            background: white;
                        }
                    }
                }
            }

            h6 {
                font-size: $font-size-normal;
                font-weight: 700;

                span {
                    font-weight: normal;
                }
            }

            .tabHOlder {
                .tabHeader {
                    display: flex;
                    gap: 25px;

                    .tabButton {
                        padding-bottom: 15px;
                        position: relative;
                        font-size: $font-size-large;
                        cursor: pointer;

                        &::after {
                            content: '';
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            right: 0;
                            border-bottom: 5px solid $primary-color;
                            opacity: 0;
                            transition: all 0.7s ease;

                        }

                        &:hover,
                        &.active {
                            &::after {
                                opacity: 1;
                            }
                        }

                        &.active {
                            font-weight: 700;
                        }
                    }
                }
            }

            .tabContentHolder {
                .tabContent {
                    border-radius: 4px;
                    margin-top: -3px;
                    border: 1px solid $primary-light;

                    .topContent {
                        border-bottom: 1px solid $primary-light;

                        .commonDiv {
                            .titleBar {
                                border-bottom: 1px solid $primary-light;
                                padding: 20px;
                            }

                            .content {
                                padding: 20px;
                                display: flex;

                                textarea {
                                    &.form-control {
                                        height: 185px;
                                    }
                                }

                                .formContainer {
                                    width: 100%;

                                    label {
                                        margin-bottom: 10px;
                                    }

                                    .inputHolder {
                                        display: flex;
                                        width: 100%;
                                        position: relative;
                                        align-items: center;

                                        .form-control {
                                            padding-right: 60px;
                                        }

                                        .icon {
                                            position: absolute;
                                            right: 10px;
                                            width: 40px;
                                            height: 40px;
                                            color: $primary-color;
                                            display: flex;
                                            align-items: center;
                                            justify-content: flex-end;
                                            font-size: 30px;
                                            background: transparent;
                                            border: 0;
                                            padding: 0;
                                        }
                                    }

                                    .repeatDiv {
                                        background: $primary-lightest;
                                        border-radius: 10px;
                                        padding: 10px;
                                        position: relative;

                                        .form-control {
                                            &:disabled {
                                                border: 0;
                                                // pointer-events: none;
                                                padding: 0;
                                                background: transparent;
                                            }
                                        }

                                        .qstnHolder {
                                            display: flex;
                                            gap: 10px;

                                            .form-control {

                                                font-weight: 700;
                                            }

                                            .btnHolder {
                                                padding: 0;
                                                display: flex;
                                                justify-content: center;
                                                gap: 10px;
                                            }
                                        }

                                        .aswrHolder {
                                            textarea {
                                                &.form-control {
                                                    height: 80px;
                                                }
                                            }
                                        }

                                    }
                                }

                                .drag_drop_container {
                                    position: relative;
                                    display: flex;
                                    width: 100%;

                                    .leftContainer {
                                        padding-right: 21px;
                                        position: relative;
                                        display: flex;
                                        width: 65%;
                                        align-items: center;
                                        justify-content: center;

                                        p {
                                            text-align: center;
                                            margin-bottom: 0;
                                            color: $darkGray;
                                        }

                                        &::after {
                                            content: '';
                                            position: absolute;
                                            right: 0;
                                            top: -20px;
                                            bottom: -20px;
                                            border-right: 1px solid $primary-light;

                                        }

                                    }

                                    .rightContainer {
                                        padding-left: 20px;
                                        position: relative;
                                        display: flex;
                                        width: 35%;
                                        flex-direction: column;
                                        max-height: 209px;
                                        overflow-y: auto;
                                        height: 209px;


                                        .repeat {
                                            background: $primary-lightest;
                                            border: 1px solid $primary-light;
                                            color: $darkGray;
                                            padding: 5px;
                                            border-radius: 5px;
                                            display: flex;
                                            justify-content: space-between;
                                            align-items: center;
                                            margin: 2px 0;

                                            p {
                                                margin-bottom: 0;
                                                text-overflow: ellipsis;
                                                overflow: hidden;
                                                white-space: nowrap;
                                            }

                                            button {
                                                width: 33px;
                                                height: 33px;
                                                min-width: 33px;
                                                border-radius: 50px;
                                                background: $white;
                                                border: 1px solid $primary-light;
                                                color: $primary-color;

                                            }
                                        }
                                    }
                                }

                                &.instructions {
                                    .form-control {
                                        border: 0;
                                    }
                                }

                                &.qaContent {
                                    height: 245px;
                                    overflow-y: auto;
                                }
                            }

                        }
                    }

                    .btnHolder {
                        padding: 20px;
                    }
                }
            }
        }
    }

    .agentDetails {
        .titleInfoBar {
            .dpHolder {
                background: $secondary-light;
                color: $primary-dark;
                border-radius: 4px;
                display: flex;
                align-items: center;
                justify-content: center;
                min-height: 116px;
                position: relative;
                flex-direction: column;
                padding: 15px;
            }

            .rightDetails {
                padding-left: 25px;

                .topLabel {
                    align-items: center;

                    h1 {
                        margin-right: 20px;
                    }
                }

                h6 {
                    font-size: $font-size-normal;
                    font-weight: 700;

                    span {
                        font-weight: normal;
                    }
                }
            }
        }

        .tabMenu {
            position: relative;
            padding-top: 25px;
            padding-bottom: 25px;
            cursor: pointer;
            height: calc(var(--window-height) - 150px);
            overflow-y: auto;

            @media (max-width: 991.98px) {
                display: flex;
                gap: 0 20px;
                white-space: nowrap;
                flex-wrap: wrap;
                height: auto !important;
            }

            &::after {
                content: '';
                position: absolute;
                right: 0;
                top: 0;
                bottom: 0;
                border-right: 1px solid $primary-light;
                @media (max-width: 991.98px) {
                    display: none;
                }
            }

            .tabButton {
                margin-left: -12px;
                margin-right: -12px;
                display: flex;
                padding: 3px 12px;
                align-items: center;
                color: $primary-text-color;
                // font-size: $font-size-medium;
                min-height: 40px;
                position: relative;
                z-index: 1;
                -webkit-border-top-left-radius: 4px;
                -webkit-border-bottom-left-radius: 4px;
                -moz-border-radius-topleft: 4px;
                -moz-border-radius-bottomleft: 4px;
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px;
                font-size: $font-size-small;
                font-family: $secondary-font-regular;

                &.active {
                    background: $white;
                    border: 1px solid $primary-light;
                    border-right: 0;
                    @media (max-width: 991.98px) {
                        background: $primary-light;
                        border: 0;
                        border-radius: 4px;
                    }
                }
            }
        }

        .tabContent {
            padding-left: 40px;
            @media (max-width: 991.98px) {
                padding-left: 0;
                padding-right: 0;
            }

            &.tab-content-holder {
                height: calc(var(--window-height) - 150px);
            }

            .commonDiv {
                height: 100%;

                .chatbotRow {
                    height: 100%;

                    .chatBotHolder {
                        height: 100%;
                    }

                    .customHolder {
                        height: 100%;

                        .top-inputs {
                            height: calc(100% - 40px);
                            overflow-y: auto;
                            overflow-x: hidden;
                        }
                    }
                }
            }
        }
    }
}

.DotLoaderHolder {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: var(--window-height);
    z-index: 9999;

    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        background: $white;
        opacity: 0.7;
    }

    span {
        position: relative;
    }
}

.promtSelection {

    .checkGroupHolder {
        .form-check {
            border: 1px solid $primary-light;
            border-radius: 29px;
            padding: 10px 15px !important;
            display: flex;

            .form-check-input {
                margin-left: 0;
                left: 15px;
            }

        }


    }
}

.form-check {
    position: relative;
    padding-left: 0 !important;
    cursor: pointer;

    input {
        width: 18px;
        height: 18px;
        position: absolute;
        left: 0;
        opacity: 0;
        margin-left: 0 !important;
        margin-top: 0;

    }

    .checkDesignHolder {
        width: 18px;
        height: 18px;
        border: 1px solid $primary-light;
        border-radius: 3px;
        margin-right: 15px;
        margin-right: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        min-width: 18px;

        span {
            display: none;
        }
    }

    input:checked+.checkDesignHolder {
        background: $primary-light;

        span {
            color: $white;
            display: block;
        }
    }
}

.infoBar {
    .breadcrumb {
        li {
            list-style: none;
            cursor: pointer;
            font-size: $font-size-smaller;
            font-family: 'Poppins', sans-serif

            button {
                border: 0;
                background: transparent;
                color: $primary-color;
                margin-right: 15px;
            }

        }
    }

    .clientInfo {
        h6 {
            font-weight: 600;
            font-family: 'Poppins', sans-serif;
        }
        .clientInfoLeft {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            .user {
                font-size: 12px;
                color: $secondary-color;
                font-family: 'Poppins', sans-serif;
            }
        }
        .clIcon {
            font-size: 30px;
            color: $secondary-color;
        }
    }
}

.Toastify {
    .Toastify__progress-bar {
        opacity: 0 !important;
    }
}

.with-guide-container {
    .right-container {
        position: relative;

        &::before {
            content: '';
            position: absolute;
            left: 1.5rem;
            right: -48px;
            top: -25px;
            bottom: 0;
            border-top-left-radius: 31px;
            background: rgb(246, 247, 251);
            background: linear-gradient(144deg, rgba(246, 247, 251, 1) 0%, rgba(255, 255, 255, 1) 100%);
        }

        .inner-deck {
            position: relative;

            .info-deck {
                align-items: flex-end;

                .info-content {
                    padding: 20px 30px 20px 45px;
                    background: $primary-lightest;

                    border-top-left-radius: 15px;
                    border-top-right-radius: 15px;
                    border-bottom-left-radius: 15px;
                    position: relative;

                    &::after {
                        display: inline-block;
                        width: 0;
                        height: 0;
                        border-style: solid;
                        border-width: 15px 0px 0px 25px;
                        border-color: transparent transparent transparent $primary-lightest;
                        content: '';
                        position: absolute;
                        right: -24px;
                        bottom: 0;
                    }

                    ul {
                        li {
                            position: relative;

                            &::before {
                                content: '';
                                width: 10px;
                                height: 10px;
                                border-radius: 3px;
                                background: $primary-color;
                                position: absolute;
                                left: -20px;
                                top: 7px;
                            }
                        }
                    }
                }

                .robo {
                    margin-left: -15px;
                    position: relative;
                }
            }
        }
    }
}

.instruction-holder {
    &:hover {
        .info {
            display: block;
        }
    }
}

.info {
    background: $primary-lightest;
    padding: 15px;
    margin-bottom: 5px;
    border-radius: 4px;
    font-size: $font-size-small;
    position: absolute;
    left: -182px;
    bottom: 0;
    width: 170px;
    display: none;

    &::before {
        position: absolute;
        width: 0px;
        height: 0px;
        border-style: solid;
        border-width: 20px 0 0 20px;
        border-color: transparent transparent transparent $primary-lightest;
        transform: rotate(0deg);
        content: '';
        right: -20px;
        bottom: 10px;
    }

}

.json-to-table td {
    white-space: nowrap;
    min-width: 350px !important;
}

.normal-number-input {

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}

.disable-div{
    opacity: 0.5;
    pointer-events: none;
}

.pagination {
    display: flex;
    justify-content: center;
    li {
        a {
            min-width: 32px;
            height: 32px;
            padding: 0 6px;
            margin: 0 3px;
            border-radius: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: 'Poppins', sans-serif;
            &:hover {
                background: $secondary-lighter;
            }
        }
        &.selected {
            a {
                background: $secondary-lighter;
            }
        }
        &.disabled {
            a {
                pointer-events: none;
            }
        }
    }
}

.leftInfo {
    @media (max-width: 991.98px) {
        margin-left: 30px;
    }
}

.rightInfo {
    position: relative;

    .clientInfo {
        cursor: pointer;
    }

    .clientDetails {
        width: 500px;
        padding: 40px;
        border-radius: 15px;
        background: $white;
        position: absolute;
        right: 0;
        top: 45px;
        z-index: 4;
        -webkit-box-shadow: 0px 0px 14px -3px rgba(0,0,0,0.52);
        -moz-box-shadow: 0px 0px 14px -3px rgba(0,0,0,0.52);
        box-shadow: 0px 0px 14px -3px rgba(0,0,0,0.52);

        p {
            margin: 0;
        }

        .clientDetailsInner {
            position: relative;

            .plain-btn {
                background: transparent;
                border: 0;
                padding: 0;
                font-size: $font-size-small;
                color: $primary-color;
    
                &.edit-option {
                    position: absolute;
                    right: 0;
                    top: -10px;
                    border: 1px solid $primary-color;
                    border-radius: 4px;
                    width: 25px;
                    height: 25px;
                    display: flex;
                    padding: 0;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
        
    }
}

.s-logo {
    width: 35px;
    padding-top: 10px;
    @media (min-width: 992px) { 
        display: none;
     }
     .logo {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 5px;
        font-size: 10px;
        color: $black;
        font-weight: 700;
        font-family: 'Poppins', sans-serif;
    }
}

@media (max-width: 991.98px) { 
    .infoBar {
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        padding: 5px 30px;
        background: $white;
        z-index: 9;
        align-items: center;
        &::before {
            width: 100%;
            height: 30px;
            position: absolute;
            content: '';
            background: $white;
            top: -30px;
            left: 0;
        }
    }
 }

 .sNav-icon {
    
    button {
        border: none;
        background: none;
        font-size: 20px;
        color: $primary-color;
        margin-left: 10px;
    }
    @media (min-width: 992px) { 
        display: none;
     }
 }

 .contentWrapper {
    @media (max-width: 991.98px) {
        margin-top: 60px;
    }
 }
 .title-bar {
    @media (max-width: 768.98px) {
        flex-direction: column;
        align-items: stretch !important;

        h2 {
            margin-bottom: 20px;
        }
    }
 }


// @media (min-width: 768px) { ... }
// @media (max-width: 767.98px) { ... }

// @media (min-width: 992px) { ... }
// @media (max-width: 991.98px) { ... }
