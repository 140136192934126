@import '../../../common/styles/_variable';

.logHolder {
  border: 1px solid $primary-light;
  background: $primary-lightest;
  border-radius: 20px;
  display: flex;
  height: 100%;
  overflow: hidden;

  @media (max-width: 767.98px) {
    flex-direction: column;
  }

  .leftItems {
    width: 30%;
    min-width: 30%;
    background: $white;
    // max-height: calc(100vh - 125px);
    overflow-y: auto;
    height: 100%;

    @media (max-width: 767.98px) {
      width: 100%;
      min-width: 100%;
    }

    a {
      padding: 15px;
      border-bottom: 1px solid $primary-light;
      display: flex;
      gap: 10px;
      justify-content: space-between;

      &.box {
        background: $primary-light;
      }

      &:last-child {
        border-bottom: 0;
      }

      .dp-chatDesc {
        display: flex;
        gap: 10px;
      }

      .chatDesc-delete {
        .close {
          background: none;
          border: none;
          color: $primary-color;
        }
      }

      .dp {
        width: 17px;
        height: 17px;
        border-radius: 50px;
        min-width: 17px;
      }
    }


  }

  .rightDetails {
    padding: 15px;
    max-height: calc(var(--window-height) - 67px);
    height: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    width: 70%;

    @media (max-width: 767.98px) {
      width: 100%;
      min-width: 100%;
    }

    .chatDetails {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }

    .msg {
      display: flex;
      padding: 0;

      .msg-text {
        display: block;
        white-space: pre-line;
        margin-bottom: 4px;
      }

      .msgInner {
        display: flex;
        background: $white;
        border-radius: 29px;
        color: $primary-dark;
        gap: 10px;
        padding: 7px 10px;

        ol {
          li {
            margin-bottom: 14px;
          }
        }

        .msg-img {
          width: 30px;
          min-width: 30px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            max-width: 80%;
          }
        }

        .msg-bubble {
          display: flex;
          align-items: flex-end;
          gap: 10px;

          .msg-text {
            overflow-wrap: anywhere;
            white-space: pre-line;

            // For hide empty div
            div:empty {
              display: none;
            }

            // For hide empty div

            &>div:first-child {
              width: 460px;
              overflow-x: auto;
              margin: 10px 0;
              padding-bottom: 5px;

              table {
                width: 100% !important;
                tr {
                  &:nth-child(even) {
                    background-color: $primary-lightest;
                  }
                }

                th,
                td {
                  border-width: 1px;
                  white-space: nowrap;
                  text-align: left;
                  padding: 8px;
                }
              }
            }
          }
        }
      }

      &.right-msg {
        .msgInner {
          margin-left: auto;

          .msg-img {
            background: $secondary-light;
            border-radius: 100%;

            svg {
              display: none;
            }
          }

        }
      }

    }



  }
}