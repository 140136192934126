@import '../../common/styles/variable';

.accordion {
    width: 100%;     
    overflow: hidden;
  }
  
  .accordion-item {
    border-top: 1px solid #ddd;
    margin-bottom: 10px;
    border-radius: 10px;
    border: none;
    background: $primary-lightest;
  }
  
  .accordion-title {    
    padding: 15px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;    
  }
  
  .accordion-content {
    padding: 0 15px 15px 15px;
    display: none;
    border-radius: 0 0 10px 10px;  
    max-height: 180px;
    overflow-x: auto;  
  }
  
  .accordion-content.show {
    display: block;
  }
  
  .arrow {
    transition: transform 0.3s ease;
  }
  
  .arrow.rotate {
    transform: rotate(90deg);
  }
  .summary-faq {
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 5px;
    background: $white;
    .question {
      font-weight: 600;
      margin-bottom: 5px;
    }
  }
  