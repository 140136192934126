.popup-container {
  position: fixed;
  left: 0;
  top: 0px;
  right: 0;
  bottom: 0;
  background: $primaryOverlayLight;
  z-index: 999;

  .shadeClose {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }

  .popup-inner {
    position: fixed;
    top: 0px;
    right: 0;
    bottom: 0;
    max-width: 768px;
    width: 50%;
    background: $white;

    .popup-header {
      position: relative;
      height: 50px;
      border-bottom: 1px solid $borderGray;
      display: flex;
      align-items: center;
      padding: 5px 30px;
      padding-right: 55px;

      .close {
        position: absolute;
        right: 0;
        height: 50px;
        background: transparent;
        color: $white;
        width: 55px;
        border: 0;
        border-left: 1px solid $borderGray;
        top: 0;
        border-radius: 0;
        color: $primary-color;
        font-size: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .popup-content {
      padding: 30px;
      overflow-y: auto;
      height: calc(var(--window-height) - 80px);
      position: relative;

      .status-holder {
        .status-radio {
          display: flex;
          gap: 15px;
          .radioBtn {
            display: flex;
            label {
              margin: 0 0 0 5px;
            }
            [type="radio"]:checked,
            [type="radio"]:not(:checked) {
              position: absolute;
              left: -9999px;
            }
            [type="radio"]:checked + label,
            [type="radio"]:not(:checked) + label {
              position: relative;
              padding-left: 28px;
              cursor: pointer;
              line-height: 20px;
              display: inline-block;              
            }
            [type="radio"]:checked + label:before,
            [type="radio"]:not(:checked) + label:before {
              content: "";
              position: absolute;
              left: 0;
              top: 0;
              width: 20px;
              height: 20px;
              border: 1px solid $primary-light;
              border-radius: 100%;
              background: $white;
            }
            [type="radio"]:checked + label:after,
            [type="radio"]:not(:checked) + label:after {
              content: "";
              width: 12px;
              height: 12px;
              background: $primary-color;
              position: absolute;
              top: 4px;
              left: 4px;
              border-radius: 100%;
              -webkit-transition: all 0.2s ease;
              transition: all 0.2s ease;
            }
            [type="radio"]:not(:checked) + label:after {
              opacity: 0;
              -webkit-transform: scale(0);
              transform: scale(0);
            }
            [type="radio"]:checked + label:after {
              opacity: 1;
              -webkit-transform: scale(1);
              transform: scale(1);
            }
          }
        }
      }
    }
  }

  &.small-popup {
    .popup-inner {
      width: 350px;
      max-width: 350px;
    }
  }

  &.medium-popup {
    .popup-inner {
      width: 700px;
      max-width: 700px;
      @media (max-width: 767.98px) {
        width: 100%;
        max-width: 100%;
      }
    }
  }

  .template-section {
    .tabHeader {
      display: flex;
      gap: 25px;

      border-bottom: 1px solid $primary-light;

      .tabButton {
        padding-bottom: 15px;
        position: relative;
        font-size: $font-size-normal;
        cursor: pointer;

        &::after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          border-bottom: 5px solid $primary-color;
          opacity: 0;
          transition: all 0.7s ease;
        }

        &:hover,
        &.active {
          &::after {
            opacity: 1;
          }
        }

        &.active {
          font-weight: 700;
        }
      }
    }

    .tabContent {
      border-radius: 4px;
      margin-top: -3px;
      padding-left: 0 !important;

      .topContent {
        .commonDiv {
          .content {
            padding: 20px 0 0;
            min-width: 100%;
            width: 100%;

            &.instructions {
              .form-control {
                border: 0;
              }
            }

            &.dsContent {
              overflow-x: auto;
            }
          }

          &.files-content {
          }

          .repeat {
            .box {
              border: 1px solid $primary-light;
              color: $darkGray;
              padding: 5px;
              border-radius: 5px;
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin: 2px 0;
              gap: 3px;

              p {
                margin-bottom: 0;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
              }

              button {
                width: 33px;
                height: 33px;
                min-width: 33px;
                border-radius: 50px;
                background: $white;
                border: 1px solid $primary-light;
                color: $primary-color;
                margin-left: auto;
              }
            }
          }

          .accordionContainer {
            .repeatDiv {
              background: $primary-lightest;
              border-radius: 10px;
              padding: 10px;
              position: relative;
              margin-bottom: 12px;
              display: flex;
              flex-direction: column;

              .accordion-header {
                position: relative;
                padding-right: 50px;

                .accordionTrgr {
                  position: absolute;
                  right: 0;
                  top: 0;
                  bottom: 0;
                  width: 50px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  font-size: 20px;
                  cursor: pointer;
                }

                &.open {
                  .accordionTrgr {
                    svg {
                      transform: rotate(180deg);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.large-popup {
    .popup-inner {
      width: 1200px;
      max-width: 1200px;

      .template-list-holder {
        box-shadow: 4px 0 6px 0 #ddd;
      }

      .template-lists {
        height: calc(var(--window-height) - 50px);
        overflow-y: hidden;
        overflow-x: hidden;
        padding-right: 0;

        .popup-content {
          height: calc(var(--window-height) - 110px);
        }

        .btnHolder {
          padding-left: 30px;
          padding-right: 30px;
        }

        .boxHolder {
          &.selectedTemplate {
            .box {
              background-color: $primary-lightest;

              .icon-btn {
                background: $primary-color;
                color: $white;
              }
            }
          }
          .box {
            padding: 15px !important;
            align-items: normal !important;
            // justify-content: flex-start !important;

            .iconHolder {
              width: 24px;
              height: 24px;
              font-size: 24px;
            }

            .info-holder {
              span {
                font-size: $font-size-smaller;
                color: $greyText;
              }
            }

            .form-check-label {
              margin-bottom: 0;
              font-size: $font-size-small;
              font-weight: 600;
              color: $primary-dark;
            }

            p {
              margin-bottom: 0;
              font-size: $font-size-normal;
              overflow-wrap: break-word;
              font-weight: normal;
            }

            .bottom-holder {
              .tag {
                background: $primary-lightest;
                border-radius: 25px;
                padding: 5px 7px;
              }
            }

            .icon-btn {
              width: 25px;
              height: 25px;
              border: 1px solid $primary-color;
              color: $primary-color;
              font-size: $font-size-normal !important;
              background: transparent;
              padding: 0;
              line-height: 13px;

              &:hover {
                background: $primary-color;
                color: $white;
              }
            }

            .chck-holder {
              position: relative;
              width: 25px;
              height: 25px;

              .form-check-input {
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                width: 100%;
                opacity: 0;
                cursor: pointer;
              }

              .checkDesignHolder {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 4px;
                border: 1px solid $primary-color;
                color: $primary-light;
                font-size: $font-size-normal;

                span {
                  display: none;
                }
              }

              .form-check-input:checked + .checkDesignHolder {
                color: $white;
                background: $primary-color;
                border-color: $primary-color;

                span {
                  display: block;
                }
              }
            }
          }
        }
      }
    }
  }

  &.confirmation-popup {
    display: flex;
    align-items: center;
    justify-content: center;

    .popup-inner {
      position: absolute;
      top: auto;
      right: auto;
      bottom: auto;
      width: 500px;
      background: $white;
      border-radius: 5px;

      .popup-content {
        height: auto;
        max-height: calc(var(--window-height) - 80px);
      }
    }
  }

  &.create-template-popup {
    display: flex;
    align-items: center;
    justify-content: center;

    .popup-inner {
      position: absolute;
      top: auto;
      right: auto;
      bottom: auto;
      width: 800px;
      background: $white;
      border-radius: 5px;

      .popup-content {
        height: auto;
        max-height: calc(var(--window-height) - 80px);
      }
    }

    .statusHolder {
      .topDiv {
        gap: 25px;
      }

      .icon {
        -moz-transform: scale(-1, 1);
        -webkit-transform: scale(-1, 1);
        -o-transform: scale(-1, 1);
        -ms-transform: scale(-1, 1);
        transform: scale(-1, 1);
        font-size: 40px;
        color: $primary-dark;
        display: flex;
      }

      .statusInfo {
        // padding-left: 35px;
      }

      h4 {
        color: $primary-dark;
        font-size: $font-size-medium;
        margin-bottom: 5px;
        font-weight: 700;
        line-height: 27px;
      }

      p {
        font-size: $font-size-normal;
        color: $primary-color;
        line-height: 20px;
      }

      ul {
        margin-left: -35px;
        position: relative;

        &::before {
          content: "";
          left: 6px;
          position: absolute;
          top: 0;
          bottom: 0;
          border-left: 1px solid $primary-light;
        }

        li {
          padding-left: 35px;
          position: relative;
          margin-bottom: 10px;

          &:last-child {
            margin-bottom: 0;
          }

          .iconSpan {
            position: absolute;
            left: 0;
            background: white;
          }
        }
      }
    }
  }

  &.status-popup {
    .popup-inner {
      .popup-header {
        border-bottom: 0;

        .close {
          border-left: 0;
        }
      }

      .popup-content {
        padding-top: 0;

        .main-status {
          .top-info {
            max-width: 90%;
            width: 60%;
            margin: 0 auto;
            display: flex;
            align-items: center;
            flex-direction: column;

            .status-bar {
              width: 100%;

              .progress {
                width: 100%;

                .progress-bar {
                  background-color: $primary-color;
                }
              }
            }
          }

          .bottom-info {
            .info-tag {
              border-radius: 20px;
              padding: 5px 10px 5px 5px;
              height: 35px;
              display: flex;

              span {
                border-radius: 20px;
                padding: 0 5px;
                margin-right: 5px;
                min-width: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
              }

              &.completed {
                background: rgba(39, 187, 29, 0.11);
                color: $green;

                span {
                  background: $green;
                  color: $white;
                }
              }

              &.queue {
                background: rgba(112, 112, 112, 0.16);
                color: $darkGray;

                span {
                  background: $darkGray;
                  color: $white;
                }
              }

              &.inprogrss {
                background: rgba(20, 49, 92, 0.18);
                color: #14315c;

                span {
                  background: #14315c;
                  color: $white;
                }
              }

              &.failed {
                background: rgba(232, 117, 114, 0.16);
                color: $danger;

                span {
                  background: $danger;
                  color: $white;
                }
              }
            }

            .btn {
              &.small {
                width: auto;
                min-width: none;
                min-height: auto;
                height: 30px;
                padding: 5px 10px;
                display: flex;
                gap: 20px;

                svg {
                  margin-right: 0;
                }
              }

              &.expanded {
                span {
                  transform: rotate(180deg);
                }
              }
            }
          }
        }

        .detailed-status {
          .status-accordion {
            display: flex;
            flex-direction: column;
            gap: 3px;

            .accordion-item {
              &.active {
                h2 {
                  &.accordion-header {
                    color: $white;
                    background: $primary-color;
                    border-bottom-right-radius: 0;
                    border-bottom-left-radius: 0;

                    span {
                      transform: rotate(180deg);
                    }
                  }
                }
              }

              h2 {
                &.accordion-header {
                  padding: 8px 10px;
                  cursor: pointer;
                  background: $primary-lightest;
                  border-radius: 4px;
                  font-size: $font-size-small;
                  font-weight: 600;
                  display: flex;
                  align-items: center;
                }
              }

              .accordion-content {
                padding: 15px;
                background: $secondary-lightest;

                ul {
                  position: relative;
                  max-height: 145px;
                  overflow-y: auto;

                  &::before {
                    content: "";
                    left: 10px;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    border-left: 1px solid $primary-light;
                    display: subgrid;
                  }

                  li {
                    padding-left: 35px;
                    position: relative;
                    margin-bottom: 15px;
                    align-items: center;
                    font-size: 13px;
                    min-height: 20px;

                    &:last-child {
                      margin-bottom: 0;
                    }

                    .icon {
                      position: absolute;
                      left: 0;
                      color: $white;
                      width: 20px;
                      height: 20px;
                      border-radius: 100px;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                    }

                    &.succes-item {
                      .icon {
                        background: $green;
                      }
                    }

                    &.inprogress-item {
                      .icon {
                        background: #14315c;
                      }
                    }

                    &.queue-item {
                      .icon {
                        background: $darkGray;
                      }
                    }

                    &.failed-item {
                      .icon {
                        background: $danger;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.dashboard-popup {
    label {
      font-size: $font-size-smaller;
    }

    textarea {
      &.form-control {
        height: 106px;
      }
    }

    .upload-button-container {
      width: 170px;
      height: 170px;
      min-width: 170px;
      position: relative;

      .uploadButton {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        padding: 15px;
        border-radius: 4px;
        background: $primary-lightest;
        height: 100%;

        input {
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          top: 0;
          opacity: 0;
          height: 100%;
        }

        .uploadTrigger {
          display: flex;
          flex-direction: column;
          gap: 10px;
          height: 100%;
          align-items: center;
          justify-content: center;

          .icon {
            font-size: 25px;
          }

          label {
            margin-bottom: 0;
            text-align: center;
            font-size: $font-size-smaller;
            font-weight: 700;
          }
        }
      }
    }
  }
}

.nameHolder {
  .uploadButton {
    position: relative;
    border: solid 1px $primary-light;
    border-radius: 4px;
    .uploadTrigger {
      width: 100%;
      height: 40px;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $primary-color;
    }
    input {
      position: absolute;
      top: 0;
      left: 0;
      height: 40px;
      opacity: 0;
    }
    .edit-icon {
      position: absolute;
      bottom: 2px;
      right: 6px;
      pointer-events: none;
    }
    .iconHolder {
      width: 100% !important;
      height: 40px !important;
      padding: 5px;

      img {
        width: auto;
        height: 100%;
      }
    }
  }
}
