@import '../../../../common/styles/_variable';

.chatoption {
    width: 40%;
    @media (max-width: 767.98px) {
        width: 100%;
      }
}
.contact-form-listing {
    .contact-form {
        @media (max-width: 767.98px) {
            width: 100%;
          }
    }    
}

.chatOption-holder {
    height: 100%;

    .chatOption-listing {
        height: calc(100% - 80px);
        overflow-y: auto;
        overflow-x: hidden;
        padding-right: 2px;
    }
}