@import '../../common/styles/_variable';

.dashboard-container {
    margin-top: -3rem;
    @media (max-width: 991.98px) {
        margin-top: 60px;
    }

    .count-holder {
        .box-holder {
            .box {
                border: 1px solid $primary-light;
                border-radius: 31px;
                padding: 25px;
                justify-content: center;
                display: flex;
                flex-direction: column;

                p {
                    margin-bottom: 0;
                }
            }

            &:first-child {
                .box {
                    border-bottom-right-radius: 0;
                }
            }

            &:nth-child(2) {
                .box {
                    border-bottom-left-radius: 0;
                    // border: 0;
                    // background: $secondary-color;
                    // color: $white;
                }
            }

            &:nth-child(3) {
                .box {
                    border-top-right-radius: 0;
                    // background: $theme-green;
                    // border: 0;
                }
            }

            &:last-child {
                .box {
                    border-top-left-radius: 0;
                }
            }
        }

    }

    .right-deck {
        position: relative;

        &::before {
            content: '';
            position: absolute;
            left: 3rem;
            right: -48px;
            top: -50px;
            bottom: 0;
            border-top-left-radius: 31px;
            background: rgb(246, 247, 251);
            background: linear-gradient(144deg, rgba(246, 247, 251, 1) 0%, rgba(255, 255, 255, 1) 100%);
            @media (max-width: 991.98px) { 
                display: none;
             }
        }

        .company-details {
            word-wrap: break-word
        }

        .inner-deck {
            position: relative;

            .logo-holder {
                .logo-pic {
                    width: 100%;
                    max-height: 120px;
                    max-height: 120px;
                    border-radius: 4px;
                    background: $white;
                    border: 1px solid $borderGray;
                    padding: 2px;
                }
            }

            .subs-info {
                padding-bottom: 5px;
                position: relative;

                // &::after {
                //     content: '';
                //     height: 1px;
                //     position: absolute;
                //     left: 14px;
                //     right: 14px;
                //     bottom: 0;
                //     border-bottom: 1px dashed $primary-light;
                // }
            }

            p {
                font-size: $font-size-small;
                margin-bottom: 0;
            }

            .plain-btn {
                background: transparent;
                border: 0;
                padding: 0;
                font-size: $font-size-small;
                color: $primary-color;

                &.edit-option {
                    position: absolute;
                    right: 0;
                    top: -35px;
                    border: 1px solid $primary-color;
                    border-radius: 4px;
                    width: 25px;
                    height: 25px;
                    display: flex;
                    padding: 0;
                    align-items: center;
                    justify-content: center;
                }
            }

            .activity {
                position: relative;
                overflow-y: auto;
                // height: 175px;
                height: auto;

                &::after {
                    content: '';
                    width: 1px;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 50%;
                    border-right: 1px dashed $primary-light;
                    @media (max-width: 767.98px) { 
                        display: none;
                     }
                }

                .repeat {
                    position: relative;
                    padding-top: 14px;
                    padding-bottom: 14px;

                    &::after {
                        content: '';
                        height: 1px;
                        position: absolute;
                        left: 14px;
                        right: 14px;
                        bottom: 0;
                        border-bottom: 1px dashed $primary-light;
                    }
                }

                .header-sec {
                    position: relative;
                    padding-left: 20px;
                    margin-bottom: 10px;

                    &::before {
                        content: '';
                        left: 0;
                        top: 3px;
                        bottom: 5px;
                        width: 5px;
                        background: $secondary-color;
                        position: absolute;
                    }

                    .date {
                        font-style: oblique;
                        color: $darkGray;
                        font-size: $font-size-smaller;
                        font-family: 'Poppins', sans-serif;
                    }
                }

                p {
                    margin-bottom: 10px;
                }

                .user-info {
                    font-size: $font-size-smaller;
                    padding: 2px 10px;
                    border: 1px solid $secondary-color;
                    background: $secondary-lighter;
                    border-radius: 50px;

                    .dp {
                        width: 20px;
                        height: 20px;
                        border-radius: 50px;
                        overflow: hidden;
                        border: 1px solid $borderGray;
                    }
                }

                .plain-btn {
                    background: transparent;
                    border: 0;
                    padding: 0;
                    font-size: $font-size-small;
                    color: $primary-color;
                }
            }
        }

    }

    .pie-holder {
        .graph-holder {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            .chart-inner {
                position: relative;
                margin-bottom: 20px;
            }

            h5 {}
        }
    }

    .area-chart-holder {
        .graph-holder {
            // max-height: 250px;
        }
    }
}