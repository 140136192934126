body,
ul,
li,
ol,
h1,
h2,
h3,
h4,
h5,
h6,
nav,
canvas {
	padding: 0;
	margin: 0;
}

a,
a:hover,
a:visited,
a:active,
a:focus,
button:visited,
button:visited,
button:focus {
	color: inherit;
	cursor: pointer;
	text-decoration: none !important;
	outline: none !important;
}

input:focus,
textarea:focus,
select:focus,
.slick-slide:focus {
	outline: none;
}

ul,
li {
	list-style: none;
}
ol {
	list-style: decimal;
	padding-left: 15px;
	li {
		list-style: decimal;
	}
}

img {
	max-width: 100%;
}

textarea {
	resize: none;
}

a {
	cursor: pointer;
}

a {
	transition: all 0.7s ease;
}
.form-control, .form-select {
	border-color: $primary-light;
	border-radius: 4px;
	font-size: $font-size-small;
	height: 40px;
	background: transparent;
	&:focus {
		box-shadow: 0 0 0 0.25rem $primaryOverlay;
	border-color: $primary-light;
	background: transparent;

	}

}
.form-select {
	background-image: url(../images//Polygon.svg);
	background-position: 97% center;
	background-repeat: no-repeat;
	&:focus {
		background-image: url(../images//Polygon.svg);
	background-position: 97% center;
	background-repeat: no-repeat;

	}
	option {
		background-color: $white;
		-moz-appearance: none;
		appearance: none;
		
	}

}
input[type="file"]{
	height: 34px;
}
textarea {
	&.form-control {
		height: 150px;
		&.height-80 {
            height: 80px !important;
        }
		&.height-100 {
            height: 100px;
        }
		&.height-350 {
            height: 350px;
        }
	}
}
label {
	color: $black;
	font-size: $font-size-small;
}

/* width */
::-webkit-scrollbar {
	width: 6px;
	height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
	background: $primary-light;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: $primary-color;
	border-radius: 10px;
}
.dataTable {
	.rdt_TableHeadRow {
		font-size: $font-size-small;
	}
	.rdt_TableRow {
		font-size: $font-size-small !important;
	
	}
	.form-select {
		height: 35px;
		&:disabled {
			background: transparent;
			background-color: transparent;
			border: 0;
			padding-left: 0;
		}
	}
	
}
.toggleSwitch {
	position: relative;
	width: 45px;
	height: 20px;
	input {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		opacity: 0;
		z-index: 1;
		cursor: pointer;
		&:disabled {
			pointer-events: none;
		}
	}		
	span {
		position: relative;
		width: 100%;
		height: 100%;
		background: $grey;
		border-radius: 25px;
		display: flex;
		&::after {
			position: absolute;
			width: 18px;
			height: 18px;
			border-radius: 100%;
			background: $white;
			content: '';
			left: 1px;
			top: 1px;
		}
	}
	
	input:checked+span {
		background: $green;
		&::after {
			left: auto;
			right: 1px;
		}
	}
}