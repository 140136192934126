@import '../../common/styles/variable';

// 
.started {
    color: $darkGray ;
    background: $light-Gray;
    border: solid 1px $darkGray;
    border-radius: 20px;
    padding: 2px 10px;
    display: flex;
    align-items: center;
    gap: 5px;
}
.training {
    color: $yellow ;
    background: $light-yellow;
    border: solid 1px $yellow;
    border-radius: 20px;
    padding: 2px 10px;
    display: flex;
    align-items: center;
    gap: 5px;
}
.complete {
    color: $green ;
    background: $light-green;
    border: solid 1px $green;
    border-radius: 20px;
    padding: 2px 10px;
    display: flex;
    align-items: center;
    gap: 5px;
}
.failed { 
    color: $danger ;
    background: $light-danger;
    border: solid 1px $danger;
    border-radius: 20px;
    padding: 2px 10px;
    display: flex;
    align-items: center;
    gap: 5px;
}