@import '../../common/styles/variable';

.div-no-record {
    background-color: rgb(250, 250, 250);
    width: 100%;
    height: 60px;
    text-align: center;
    margin: auto;
    padding-top: 20px;
  }
