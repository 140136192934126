// Colors
$primary-color: #48A3F6;
$primary-dark : #0B79DD;
$primary-light : #A9CCEB;
$primary-lightest : #E8F3FD;
$primary-trans : #F1ECF1;

$secondary-color: #57C0D6;
$secondary-light1: #FAEFF9;
$secondary-light: #DBF9FF;
$secondary-lighter: #E8F3FD;
$secondary-lightest: #FFF8FE;

$theme-green: #74F89D;

$black : #000000;
$primary-text-color : #000000;
$darkGray : #707070;
$borderGray : #DCDCDC;

$gray-light: #F2F2F2;
$white: #ffffff;
$light: #FAFAFA;
$grey: #E4E4E4;
$dark : #333333;
$green : #05D8BC;
$danger : #E87572;
$greyText : #979797;
$yellow : #F8C22D;

$overlay: rgba(0, 0, 0, 0.12);
$primaryOverlayLight: rgba(72, 163, 246, 0.22);
$primaryOverlay: rgba(250, 239, 249, 0.8);
$whiteTrans : rgba(255, 255, 255, 0.5);

$light-green : rgba(5, 216, 188, 0.1);
$light-danger : rgba(232, 117, 114, 0.1);
$light-yellow : rgba(248, 194, 45, 0.1);
$light-Gray : rgba(112, 112, 112, 0.1);

// font size
$font-size-bigger:50px;
$font-size-biggest:35px;
$font-size-big:30px;
$font-size-larger:20px;
$font-size-large:20px;
$font-size-medium:18px;
$font-size-normal:16px;
$font-size-small:14px;
$font-size-smaller:12px;
$font-size-extra-small:10px;

$larger-device-width : 2000px;