@import '../../common/styles/_variable';
.create-template {
    .tabMenu {
        position: relative;
        padding-top: 25px;
        padding-bottom: 25px;
        cursor: pointer;
        height: calc(var(--window-height) - 150px);
        &::after {
            content: '';
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            border-right: 1px solid $primary-light;
        }
    
        .tabButton {
            margin-left: -12px;
            margin-right: -12px;
            display: flex;
            padding: 3px 12px;
            align-items: center;
            color: $primary-text-color;
            // font-size: $font-size-medium;
            min-height: 40px;
            position: relative;
            z-index: 1;
            -webkit-border-top-left-radius: 4px;
            -webkit-border-bottom-left-radius: 4px;
            -moz-border-radius-topleft: 4px;
            -moz-border-radius-bottomleft: 4px;
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
            font-size: $font-size-small;
    
            &.active {
                background: $white;
                border: 1px solid $primary-light;
                border-right: 0;
            }
        }
    }
    .tabContent {
        padding-left: 40px;
        .commonDiv {
            height: 100%;
        }
    }

    
}


.template-section {
    .tabHeader {
        display: flex;
        gap: 25px;
        
        border-bottom: 1px solid $primary-light;

        .tabButton {
            padding-bottom: 15px;
            position: relative;
            font-size: $font-size-normal;
            cursor: pointer;

            &::after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                border-bottom: 5px solid $primary-color;
                opacity: 0;
                transition: all 0.7s ease;

            }

            &:hover,
            &.active {
                &::after {
                    opacity: 1;
                }
            }

            &.active {
                font-weight: 700;
            }
        }
    }

    .tabContent {
        border-radius: 4px;
        margin-top: -3px;
        padding-left: 0 !important;

        .topContent {
            .commonDiv {
              
                .content {
                    padding: 20px 0 0;
                    min-width: 100%;
                    width: 100%;
                   

                   
                    &.instructions {
                        .form-control {
                            border: 0;
                        }
                    }

                  

                    &.dsContent {
                        overflow-x: auto;
                    }


                    

                   
                }
                &.files-content {
                  
                   
                }
                .repeat {
                    .box{
                        border: 1px solid $primary-light;
                        color: $darkGray;
                        padding: 5px;
                        border-radius: 5px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin: 2px 0;
                        gap: 3px;

                        p {
                            margin-bottom: 0;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            white-space: nowrap;
                        }

                        button {
                            width: 33px;
                            height: 33px;
                            min-width: 33px;
                            border-radius: 50px;
                            background: $white;
                            border: 1px solid $primary-light;
                            color: $primary-color;
                            margin-left: auto;

                        }
                    }
                 
                }
              
                .accordionContainer {
                    .repeatDiv {
                        background: $primary-lightest;
                        border-radius: 10px;
                        padding: 10px;
                        position: relative; 
                        margin-bottom: 12px;
                        display: flex;
                        flex-direction: column;
                        .accordion-header {
                            position: relative;
                            padding-right: 50px;
    
                            .accordionTrgr {
                                position: absolute;
                                right: 0;
                                top: 0;
                                bottom: 0;
                                width: 50px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                font-size: 20px;
                                cursor: pointer;
                            }
    
                            &.open {
                                .accordionTrgr {
                                    svg {
                                        transform: rotate(180deg);
                                    }
                                }
                            }
                        }
                    }
                  
                }
            }
        }

        
    }
}



