@import '../../../../common/styles/_variable';

.contact-form {
    width: 40%;
}

.contact-form-holder {
    height: 100%;

    .contact-form-listing {
        height: calc(100% - 80px);
        overflow-y: auto;
        overflow-x: hidden;
        padding-right: 2px;
    }
}