@import '../../../common/styles/variable';

.mlAccordion {
    border-bottom: solid 1px $primary-light;
    .items {
        display: flex;
        align-items: center;
        gap: 20px;
        padding: 15px 0;  
        .chck-holder {
            min-width: 25px;
        }      
    }
    .mlAccordion {
        border: none;
        .items {
            padding: 10px 0;            
        }
    }
}