@import "../../../common/styles/_variable";

.integrationsHolder {
  ul {
    li {
      margin-bottom: 20px;
      a {
        border: 1px solid $primary-light;
        border-radius: 5px;
        padding: 20px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 15px;
        position: relative;
        p {
          font-weight: 700;
          margin-bottom: 0;
        }
        .coming-soon {
          position: absolute;
          right: 0;
          bottom: 10px;
          background: rgba(224, 224,224, 0.8);
          padding: 5px 10px;
          border-radius: 5px 0 0 5px;
          text-transform: uppercase;
          font-size: 12px;
        }
      }
    }
  }
}
