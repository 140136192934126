.sideBar {

    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 4;
    top: 0;
    bottom: 0;
    left: 0;
    width: 138px;
    background-color: $white;
    border-top-right-radius: 29px;
    border-bottom-right-radius: 29px;
    box-shadow: 10px 0px 20px $overlay;
    padding: 30px 0;
    gap: 15px;
    transition: .5s;

    @media (max-width: 991.98px) {
        left: -150px;
        top: 80px;
        padding: 0;
    }

    .logo {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 5px;
        font-size: $font-size-smaller;
        color: $black;
        font-weight: 700;
        font-family: 'Poppins', sans-serif;
        @media (max-width: 991.98px) {
            display: none;
        }
    }
    .sidebar-outer {
        width: 100%;
        height: calc(100% - 130px);
    }
    .sidebar-holder {
        position: relative;
        width: 100%;
        max-height: 100%;
    }
    nav {
        max-height: calc(var(--window-height) - 185px);
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
       
        a {
            display: flex;
            flex-direction: column;
            color: $black;
            align-items: center;
            justify-content: center;
            font-size: $font-size-smaller;
            width: 100%;
            // min-height: 94px;
            padding: 15px 0;
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
            &:first-child {
                @media (max-width: 991.98px) {
                    border-radius: 0 29px 0 0;
                }
            }

            .icon {

                svg {
                    width: 25px;
                }
            }

            &.active,
            &:hover {
                background: $secondary-lighter;
                color: $primary-color;
                .icon {
                    >svg {
                        path {
                            transition: all 0.7s ease;
                            stroke : $primary-color;
                        }
                    }
                }
            }
            .sub-menu-holder {
                width: 100%;
                div {
                    width: 100%;
                }
            }
            .subMenu {
                background: $white;
                -webkit-border-top-right-radius: 10px;
                -webkit-border-bottom-right-radius: 10px;
                -moz-border-radius-topright: 10px;
                -moz-border-radius-bottomright: 10px;
                border-top-right-radius: 10px;
                border-bottom-right-radius: 10px;
                // box-shadow: 4px 0 5px $overlay;
                width: 100%;
                max-height: 100%;
                overflow-y: auto;
                margin-bottom: -3px;
                border: 1px solid $primary-light;
                display: flex;
                flex-direction: column;
                position: absolute;
                font-size: 12px;
                color: $primary-dark;
                width: 138px;
                right: -138px;
                bottom: 50px;
                // margin-bottom: 25px;
                a {
                    font-size: $font-size-smaller;
                    position: relative;
                    padding: 10px 0;
                    .icon {
                        font-size: $font-size-large;
                    }
                    &::after {
                            content: '';
                            height: 1px;
                            border-bottom: 1px solid $primary-lightest;
                            position: absolute;
                            left: 15px;
                            right: 15px;
                            bottom: 0;
                    }
                }
            }
        }
    }

    .bottomUnit {
        position: absolute;
        bottom: 30px;
        display: flex;
        left: 0;
        right: 0;
        justify-content: center;

        .userDeck {
            width: 100%;
            position: relative;
            display: flex;
            justify-content: center;
        }

        .dpDeck {
            position: relative;
            display: flex;
            justify-content: center;

        }

        .dp {
            width: 40px;
            height: 40px;
            border-radius: 100%;
            background: $primary-color;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            color: $white;
            font-size: 26px;
            cursor: pointer;
        }

        .popInfo {
            // display: none;
            display: flex;
            flex-direction: column;
            position: absolute;
            bottom: 40px;
            font-size: $font-size-smaller;
            color: $primary-dark;
            width: 190px;
            right: -190px;
            align-items: center;
            bottom: 0;
            // &::after {
            //     content: '';
            //     width: 20px;
            //     height: 15px;

            //     position: absolute;
            //     bottom: 2px;
            //     background: url(../images/arrow.png) center center no-repeat;

            // }
            .infoInner {
                background: $white;
                -webkit-border-top-right-radius: 10px;
                -webkit-border-bottom-right-radius: 10px;
                -moz-border-radius-topright: 10px;
                -moz-border-radius-bottomright: 10px;
                border-top-right-radius: 10px;
                border-bottom-right-radius: 10px;
                // box-shadow: 4px 0 5px $overlay;
                width: 100%;
                margin-bottom: -3px;
                border: 1px solid $primary-light;

                .user-name {
                    padding: 10px;
                    // border-bottom: 1px solid $primary-light;
                    font-weight: 700;
                    position: relative;
                    &::after {
                        content: '';
                        height: 1px;
                        border-bottom: 1px solid $primary-lightest;
                        position: absolute;
                        left: 15px;
                        right: 15px;
                        bottom: 0;
                    }

                    span {
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                        display: block;
                        svg {
                            color: $primary-color;
                            font-size: $font-size-normal;
                            margin-right: 5px;
                        }
                    }
                }

                a {
                    padding: 10px;
                    display: flex;
                    svg {
                        color: $primary-color;
                        font-size: $font-size-normal;
                        margin-right: 5px;
                    }
                }
            }
        }
    }
}